import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../breadcrums"
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { allEvseAction } from "../../../store/actions/evseAction";
import RemoteEVSEListComponent from "./remote_list";
import { useTranslation } from "react-i18next";

function RemoteManagementComponent(props) {
  const { t } = useTranslation();

  useEffect(() => {
    props.all();
  }, []);

  const data = props.list && props.list.data && props.list.data.filter(item => item.status !== "Discovery")
  const total = props.list && props.list.data?.length;
  const activec =
    props.list && props.list.data?.filter((lc) => lc.status === "Available");
  const comingsoon =
    props.list && props.list.data?.filter((lc) => lc.status === "Coming soon");
  const maintenance =
    props.list && props.list.data?.filter((lc) => lc.status === "Maintenance");

  // console.log("data", data);

  return (
    <Container fluid>
      <PureBreadcrumbs />
      <br />
      <Row>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL LOCATION")}</Card.Text>
              <Card.Title>
                <b>{total !== undefined ? total : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>
                0.0% {t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/evse/totallocation.svg"
                    }
                    alt="evse"
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("AVAILABLE")}</Card.Text>
              <Card.Title>
                <b>{activec !== undefined ? activec.length : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>
                0.0% {t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/evse/availablelocation.svg"
                    }
                    alt="evse"
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("COMING SOON")}</Card.Text>
              <Card.Title>
                <b>{comingsoon !== undefined ? comingsoon.length : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>
                0.0% {t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/evse/commingssoonlocation.svg"
                    }
                    alt="evse"
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("OUT OF ORDER")}</Card.Text>
              <Card.Title>
                <b>{maintenance !== undefined ? maintenance.length : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>
                0.0% {t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/evse/inoperativelocation.svg"
                    }
                    alt="evse"
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <div className="table-header">{t("Remote EVSE Management")}</div>
            {props.loading ? <h3 style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "740px",
            }}>Loading data...</h3> :
              data && data && <RemoteEVSEListComponent {...props} data={data}></RemoteEVSEListComponent>}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.evse.isEvseLoading,
    list: state.evse.allevse,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: () => dispatch(allEvseAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RemoteManagementComponent);
