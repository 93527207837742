import { FETCH_TARIFF_NAME, PRICE_URL, STATION_URL, UPDATE_NEW_TIME_SLOT_API, SAVE_TARIFF_API, FETCH_ALL_TARIFF_API, FETCH_SINGLE_TARIFF_API, UPDATE_TARIFF_API, FETCH_ALL_CURRENCY_API, FETCH_ALL_CHARGING_UNIT_API, FETCH_ALL_TARIFF_TYPE_API, TARIFF_PERCENTAGE_CHANGE_API, CREATE_NEW_TIME_SLOT_API, FETCH_NEW_TIME_SLOT_API, FETCH_ALL_NEW_TIME_SLOT_API, FETCH_ACTIVE_TARIFF_API, SAVE_NEW_TIME_SLOT_API, FETCH_ALL_NEW_TIME_SLOT, FETCH_SINGLE_TIME_SLOT_TARIFF, UPDATE_NEW_TIME_SLOT, FETCH_STATION_TIME_SLOT_NAME, ACTIVATE_TARIFF, DEACTIVATE_TARIFF, ACTIVATE_TARIFF_TIME_SLOT, DEACTIVATE_TARIFF_TIME_SLOT, FETCH_COUNTRY_CODE_API } from "../../components/config/config";
import { ApiHandler } from "./apiHandler";

export const activateTariff = (id) => {
    console.log("activate tariff", id);
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(STATION_URL + ACTIVATE_TARIFF + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'ACTIVATE_TARIFF_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}
export const activateTimeSlotTariff = (id) => {
    // console.log("time slot id",id)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(STATION_URL + ACTIVATE_TARIFF_TIME_SLOT + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'ACTIVATE_TARIFF_TIME_SLOT_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}
export const deActivateTariff = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(STATION_URL + DEACTIVATE_TARIFF + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'DEACTIVATE_TARIFF_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}
export const deActivateTariffTimeSlot = (id) => {
    // console.log("time slot id",id)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(STATION_URL + DEACTIVATE_TARIFF_TIME_SLOT + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'DEACTIVATE_TARIFF_TIME_SLOT_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const getTariffName = (name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log(organisation)
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(PRICE_URL + FETCH_TARIFF_NAME + "?name=" + name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_NAME', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}
export const getStationName = (name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log(organisation)
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(PRICE_URL + FETCH_STATION_TIME_SLOT_NAME + "?station_name=" + name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_TIMESLOT_STATION_NAME', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const saveTariffAction = (record) => {
    const { name, description, unit, amount, currency, type, status } = record;
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(token)
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(PRICE_URL + SAVE_TARIFF_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "name": name,
                'description': description,
                'unit': unit,
                "amount": amount,
                "currency": currency,
                "type": type,
                "status": status,
                "role": role,
                "organisation": organisation
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'CREATE_TARIFF', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const allTariffAction = () => {
    // console.log("tarif action begins")
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(organisation)
    const controller = new AbortController();
    const { signal } = controller;
    // console.log("signal: " + signal);
    const bearer = 'basic ' + token
    return async dispatch => {
        // console.log("inside dispatch: ")
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(PRICE_URL + FETCH_ALL_TARIFF_API + "?role=" + role + "&organisation=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            signal,
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'LOAD_TARIFF_ALL', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const singleTariffAction = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(PRICE_URL + FETCH_SINGLE_TARIFF_API + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'LOAD_SINGLE_TARIFF', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const updateTariffAction = (record, id) => {
    const { name, description, unit, amount, currency, type, status } = record;
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(role,organisation)
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(PRICE_URL + UPDATE_TARIFF_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "id": id,
                "name": name,
                'description': description,
                'unit': unit,
                "amount": amount,
                "currency": currency,
                "type": type,
                "status": status,
                "role": role,
                "organisation": organisation
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'UPDATE_TARIFF', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const allCurrencyAction = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(PRICE_URL + FETCH_ALL_CURRENCY_API, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_ALL_CURRENCY', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}
export const fetchCountryCodeApi = (code) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(PRICE_URL + FETCH_COUNTRY_CODE_API + "?currency=" + code, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_COUNTRY_CODE_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}


export const allChargingUnitAction = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(PRICE_URL + FETCH_ALL_CHARGING_UNIT_API, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_ALL_CHARGING_UNIT', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const allTariffTypeAction = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(PRICE_URL + FETCH_ALL_TARIFF_TYPE_API, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_ALL_TARIFF_TYPE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const percentageChangeData = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(organisation)
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(PRICE_URL + TARIFF_PERCENTAGE_CHANGE_API + "?role=" + role + "&organisation=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'TARIFF_PERCENTAGE_CHANGE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const singleTimeSlotTariff = (id) => {
    // console.log(id)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(STATION_URL + FETCH_SINGLE_TIME_SLOT_TARIFF + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_SINGLE_TARIFF_TIME_SLOT', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchAllTimeSlot = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(organisation)
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(STATION_URL + FETCH_ALL_NEW_TIME_SLOT + "?role=" + role + "&operator=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },

        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_ALL_TARIFF_TIME_SLOT', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const saveTimeTariffSlot = (record) => {
    const { timeslots, station_name, plan_name } = record;
    // console.log(timeslots)
    const { token, role, userid, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(token)
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(STATION_URL + SAVE_NEW_TIME_SLOT_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "timeslot": timeslots,
                "station_name": station_name,
                "plan_name": plan_name,
                "role": role,
                "operator": organisation

            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'SAVE_NEW_TARIFF_TIME_SLOT', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const updateNewTimeSlotTariff = (record, id) => {
    const { timeslots, station_name, plan_name } = record;
    // console.log(timeslots)
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(token)
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(STATION_URL + UPDATE_NEW_TIME_SLOT, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "id": id,
                "station_name": station_name,
                "plan_name": plan_name,
                "timeslot": timeslots,
                "role": role,
                "operator": organisation
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'UPDATE_TIMESLOT', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const saveTimeSlotTariffAction = (countryCode, record) => {
    const { components, currency, description, name, status, idleTime, idlePrice, gracePeriod, isPeak } = record;
    // console.log(idleTime)
    const { token, role, userid, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(token)
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(STATION_URL + CREATE_NEW_TIME_SLOT_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                'ispeak': isPeak,
                'components': components,
                'currency': currency,
                "description": description,
                "name": name.trim(),
                "idleTime": idleTime,
                "idlePrice": idlePrice,
                "gracePeriod": gracePeriod,
                "role": role,
                "created_by": userid,
                "operator": organisation,
                "status": status,
                "country_code": countryCode,
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'CREATE_TIMESLOT_PRICE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchAllTimeSlotAction = (page, limit) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(organisation)
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(STATION_URL + FETCH_ALL_NEW_TIME_SLOT_API + "?role=" + role + "&operator=" + organisation + "&page=" + page + "&limit=" + limit, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },

        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_TIMESLOT_PRICE_LIST', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchAllStaticTariffDataAction = (page, limit) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(organisation)
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(STATION_URL + FETCH_ALL_NEW_TIME_SLOT_API + "?role=" + role + "&operator=" + organisation + "&page=" + page + "&limit=" + limit, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },

        })
        if (data) {
            // console.log("FETCH_ALL_STATIC_TARIFF_DATA_REDUCER", data)
            dispatch({ type: 'FETCH_ALL_STATIC_TARIFF_DATA_REDUCER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchActiveTariffList = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(organisation)
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(STATION_URL + FETCH_ACTIVE_TARIFF_API + "?role=" + role + "&organisation=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_ACTIVE_TARIFF_NAME', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const singleTimeOfUseTariffAction = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(STATION_URL + FETCH_NEW_TIME_SLOT_API + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_TIMESLOT_PRICE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const updateTimeSlotTariffAction = (countryCode, record) => {
    // console.log(record)
    const { components, currency, description, name, idleTime, idlePrice, gracePeriod, status, role, operator, isPeak } = record;
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log(token)
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'LOAD_TARIFF', payload: '' })
        const data = await ApiHandler(STATION_URL + UPDATE_NEW_TIME_SLOT_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                'ispeak': isPeak,
                'components': components,
                'currency': currency,
                "description": description,
                "name": name.trim(),
                "idleTime": idleTime,
                "idlePrice": idlePrice,
                "gracePeriod": gracePeriod,
                "role": role,
                "operator": operator,
                "status": status,
                "country_code": countryCode
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'UPDATE_TIMESLOT_PRICE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}