import React, { useMemo, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  allCustomerWalletAction,
  updateCustomerWalletAction,
} from "../../../../store/actions/walletActions";
import "../../reacttable/reactTable.css";
import moment from "moment";
import Table from "../../reacttable/table";
import { FaDownload, FaEdit, FaEye } from "react-icons/fa";
import Popup from "reactjs-popup";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import CustomReportPaginationTable from "../../report/adminReport/customReportPaginationTable";

function WalletList(props) {
  const { t } = useTranslation();
  const { allWalletData } = props;
  const [open, setOpen] = useState(false);
  const [amt, setAmt] = useState(0);
  const [uw, setUW] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const document_count = props.wallets && props.wallets.document_count;
  const defaultPageSize = 10;
  const pageCount = Math.ceil(document_count / defaultPageSize)
  // console.log("allWalletData from child table", allWalletData)
  useEffect(() => {
    props.getWallet(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const closeModal = () => {
    setAmt(0);
    setOpen(false);
  };

  const Add = (num, num1) => {
    const n1 = parseInt(num);
    const n2 = parseInt(num1);
    let ans = n1 + n2;
    return ans;
  };

  const updateWalletAmount = (e, userid, id) => {
    props.updateCustomer(userid, amt, id);
    // closeModal();
  };

  useEffect(() => {
    if (props.cusres.statuscode === 201) {
      window.location.reload();
    }
  }, [props.cusres]);

  const data = props.wallets && props.wallets.customers;
  // console.log(data)
  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstname",
      },
      {
        Header: "Last Name",
        accessor: "lastname",
      },
      {
        Header: "User Name",
        id: "username",
        accessor: "userid",
      },
      {
        Header: "Available Wallet Amount",
        accessor: "amount",
      },
      {
        Header: "Wallet Type",
        accessor: "type",
      },
      {
        Header: "Wallet Created Date",
        id: "date",
        accessor: "date",
        Cell: (item) => {
          const date = item.cell
          // console.log("date", date)
          return moment(date).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Actions",
        // id: "actions",
        accessor: "userid",
        Cell: (item) => {
          const userid = item.cell
          // console.log("userid", userid);
          return (
            <div>
              <span>
                <a
                  href={`/dashboard/walletcard/viewwallet/${userid}`}
                  rel="tooltip"
                  title="View"
                >
                  <FaEye />
                </a>
              </span>
              &nbsp;&nbsp;
              <span>
                <a
                  href={`/dashboard/walletcard/updatewallet/${userid}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEdit />
                </a>
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  const headers = [
    { label: 'User Name', key: 'userid' },
    { label: 'First Name', key: 'firstname' },
    { label: 'Last Name', key: 'lastname' },
    { label: 'Available Wallet Amount', key: 'amount' },
    { label: 'Wallet Type', key: 'type' },
    { label: 'Wallet Created Date', key: 'date' },
  ];

  const initialVisibleColumns =
    [
      'firstname', 'lastname', 'amount', 'type', 'date',
      'userid',
    ];

  return (
    <div>
      {props.loading ? <h3 style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "250px",
      }}>Loading data...</h3> :
        data && data.length > 0 ? (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="table-header">{t("Customer Wallet List")}</div>
              <button className="csv-file-btn">
                <CSVLink
                  className="fas"
                  data={allWalletData}
                  headers={headers}
                  filename="wallet_data.csv"
                  style={{ textDecoration: 'none' }}
                >
                  <FaDownload className="download-icon" />
                </CSVLink>
              </button>
            </div>
            {" "}
            {/* <Table data={data} columns={columns}></Table>*/}
            <CustomReportPaginationTable
              data={data}
              allData={allWalletData}
              pageCount={pageCount}
              columns={columns}
              search={search}
              setSearch={setSearch}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              loading={props.loading}
              initialVisibleColumns={initialVisibleColumns}
              t={t}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3 className="loader">{t("No data found")}</h3>
          </div>
        )}
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className="card">
          <div className="card-body">
            <div className="row justify-content-start">
              <div className="col-6">
                <p className="">Full Name</p>
              </div>
              <div className="col-6">
                <p className="">
                  {uw.firstname} {uw.lastname}
                </p>
              </div>
            </div>
            <hr></hr>
            <div className="row justify-content-start">
              <div className="col-6">
                <p className="">Available Balance</p>
              </div>
              <div className="col-6">
                <p className="">
                  <span className="me-2">$</span>
                  {uw.amount}
                </p>
              </div>
            </div>
            <hr></hr>
            <div className="row justify-content-start">
              <div className="col-6">
                <p className="">Add Amount</p>
              </div>
              <div className="col-6">
                <div class="mb-3">
                  <input
                    type="number"
                    class="form-control"
                    id="amtFormControlInput1"
                    placeholder="Update Amount"
                    value={amt || ""}
                    onInput={(e) => setAmt(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <hr></hr>
            {amt != 0 ? (
              <div className="row justify-content-start">
                <div className="col-6">
                  <h3 className="fw-bold">Total Update Amount</h3>
                </div>
                <div className="col-6">
                  <h2 className="fw-bold">
                    <span className="me-2">$</span>
                    {Add(amt, uw.amount)}
                  </h2>
                </div>
              </div>
            ) : (
              ""
            )}

            <div class="d-flex justify-content-center mb-2">
              <button
                type="button"
                class="btn btn-dark me-5"
                onClick={(e) => updateWalletAmount(e, uw.userid, uw.id)}
              >
                Update Amount
              </button>
              <button
                type="button"
                class="btn btn-outline-danger ms-1"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.wallet.isLoading,
    wallets: state.wallet.fetchAll,
    cusres: state.wallet.updatecust,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getWallet: (currentPage, pageSize) => dispatch(allCustomerWalletAction(currentPage, pageSize)),
    updateCustomer: (userid, amount, id) => dispatch(updateCustomerWalletAction(userid, amount, id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WalletList);
