import { FETCH_HEATMAP_GRAPH, FETCH_ENERGY_REVENUE_INSIGHT_GRAPH, FETCH_LIFETIME_DAILY_LIST_API, FETCH_LIFETIME_WEEKLY_LIST_API, FETCH_LIFETIME_MONTHLY_LIST_API, STATION_URL, PREDICTIVE_URL, FETCH_TOTAL_EVSE_STATUS_API, FETCH_TOTAL_CHARGING_API, FETCH_HRS_SESSION_API, FETCH_TOTAL_CITY_SESSION_API, FETCH_SESSION_TYPE_API, FETCH_TOTAL_PASS_FAIL_SESSION_API, CHARGING_SERVICE_URL, FETCH_CITY_COMPARISION_API, FETCH_TOTAL_STATION_SESSION_API, FETCH_STATION_COMPARISION_API, FETCH_WEEKLY_SESSION_API, FETCH_MONTHLY_SESSION_API, FETCH_LIFETIME_DATA_API, FETCH_DATERANGE_SESSION_TYPE, FETCH_DATERANGE_SESSION_STATUS, FETCH_CUSTOMER_TOP5_SEARCH_PREDICTION, FETCH_CUSTOMER_SEARCH_PREDICTION, INSIGHT_PERCENTAGE_CHANGE_API, GET_NOTIFICATION, POST_NOTIFICATION, SAVE_MARK_AS_ALL_READ, FETCH_COUNTRIES_CODE_API, FETCH_TRANSLATION_API, BASE_URL, COMMUNICATION_URL, FETCH_CO2_COUNT_INSIGHT_API, FETCH_CO2_LIST_INSIGHT_API } from "../../components/config/config"
import { ApiHandler } from "./apiHandler";

export const fetchCountryCode = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, userid, role, organisation } = user;
  // const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  // console.log(token)
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(BASE_URL + FETCH_COUNTRIES_CODE_API, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    // .then(resp => resp.json()).then(data => {
    //   // console.log(" data from action", data);
    //   dispatch({ type: 'FETCH_COUNTRY_CODE', payload: data })
    // })
    if (data) {
      dispatch({ type: 'FETCH_COUNTRY_CODE', payload: data });
    } else {
      console.log("No data found from Api response")
    }
  }
}

export const fetchTranslation = () => {
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(BASE_URL + FETCH_TRANSLATION_API, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log(" data from action", data);
      dispatch({ type: 'FETCH_TRANSLATION_JSON', payload: data })
    } else {
      console.log("No data found from Api response")
    }
  }
}

export const postNotificationAction = (id) => {
  // console.log("id from action",id)
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token } = user;
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(COMMUNICATION_URL + POST_NOTIFICATION, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "id": id,
      })
    })
    if (data) {
      // console.log(data)
      dispatch({ type: 'POST_NOTIFICATION_API', payload: data })
    } else {
      console.log("No data found from API response")
    }
  }
}

export const fetchMarkasread = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, userid, role, organisation } = user;
  // const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  // console.log(token)
  return async dispatch => {
    // console.log(PREDICTIVE_URL + SAVE_MARK_AS_ALL_READ + "?userid=" + userid + "&operator=" + organisation + "&role=" + role)
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(BASE_URL + SAVE_MARK_AS_ALL_READ + "?userid=" + userid + "&operator=" + organisation + "&role=" + role, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log(" data from action", data);
      dispatch({ type: 'MARK_AS_READ_API', payload: data })
    } else {
      console.log("No Data found from Api response")
    }
  }
}

export const fetchNotificationDataAPI = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, userid, role, organisation } = user;
  const bearer = 'basic ' + token
  // console.log(token)
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(COMMUNICATION_URL + GET_NOTIFICATION + "?userid=" + userid + "&role=" + role + "&operator=" + organisation, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log(" data from action", data);
      dispatch({ type: 'FETCH_NOTIFICATION_API', payload: data })
    } else {
      console.log("No data found from Api response.")
    }
  }
}
// export const fetchNotificationCount = (data) => {
//   return dispatch => {
//     dispatch({type:'INSIGHT_LOADING',payload:''})
//      return dispatch({type: 'FETCH_NOTIFICATION_COUNT'})
//     }
// }
// export const addNotificationCount = (data) => {
//   return dispatch => {
//     dispatch({type:'INSIGHT_LOADING',payload:''})
//      return dispatch({type: 'ADD_NOTIFICATION_COUNT', payload: data})
//     }
// }
// export const removeNotificationCount = (data) => {
//   return dispatch => {
//     dispatch({type:'INSIGHT_LOADING',payload:''})
//      return dispatch({type: 'REMOVE_NOTIFICATION_COUNT', payload: data})
//     }
// }
export const fetchHeatMapGraph = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  // console.log(org);
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_HEATMAP_GRAPH + "?operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("data from action",data)
      dispatch({ type: 'FETCH_HEATMAP_GRAPH', payload: data })
    } else {
      console.log("No data from Api Response")
    }
  }
}

export const fetchEnergyAndRevenueGraph = (start, end) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  // console.log(org);
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_ENERGY_REVENUE_INSIGHT_GRAPH + "?operator=" + operator + "&start=" + start + "&end=" + end, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_ENERGY_REVENUE_INSIGHT_GRAPH', payload: data })
    } else {
      console.log("No data found from Api response.")
    }
  }
}

export const fetchLifeTimeDailyList = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role, region } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  // console.log(org);
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_LIFETIME_DAILY_LIST_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_LIFETIME_DAILY_LIST', payload: data })
    } else {
      console.log("No data found from Api server")
    }
  }
}

export const fetchLifeTimeWeeklyList = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role, region } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  // console.log(org);
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_LIFETIME_WEEKLY_LIST_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_LIFETIME_WEEKLY_LIST', payload: data })
    } else {
      console.log("No data found from Api server")
    }
  }
}

export const fetchLifeTimeMonthlyList = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role, region } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  // console.log(org);
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_LIFETIME_MONTHLY_LIST_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_LIFETIME_MONTHLY_LIST', payload: data })
    } else {
      console.log("No data found from Api server")
    }
  }
}

export const fetchEVSEStatusAction = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role, region } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_TOTAL_EVSE_STATUS_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&role=" + role, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    });
    if (data) {
      // console.log("evse status", data);
      dispatch({ type: 'LOAD_CHARGER_EVSE_STATUS', payload: data })
    } else {
      console.log("No data found from Api")
    }
  }
}

export const fetchChargingStatusAction = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role, region } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_TOTAL_CHARGING_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      console.log("LOAD_TOTAL_CHARGING_STATUS", data)
      dispatch({ type: 'LOAD_TOTAL_CHARGING_STATUS', payload: data })
    } else {
      console.log("No data found from Api server")
    }
  }
}

export const fetchHrsSessionTypeAction = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role, region } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_HRS_SESSION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'LOAD_HRS_SESSION', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchCitySessionAction = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role, region } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token;

  return async dispatch => {
    dispatch({ type: 'INSIGHT_CITY_GRAPH_LOADING', payload: '' });

    try {
      const response = await fetch(
        `${PREDICTIVE_URL}${FETCH_TOTAL_CITY_SESSION_API}?region1=${region1}&region2=${region2}&region3=${region3}&region4=${region4}&operator=${operator}`,
        {
          method: "GET",
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          }
        }
      );

      if (!response.ok) {
        // If response is not ok, throw an error to be caught in the catch block
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      dispatch({ type: 'LOAD_TOTAL_CITY_CONSUMPTION', payload: data });
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error('Error fetching city session data:', error);
      // Optionally, dispatch an error action to handle errors in the UI
    }
  };
};

export const fetchStationSessionAction = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role, region } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token;

  return async dispatch => {
    dispatch({ type: 'INSIGHT_STATION_GRAPH_LOADING', payload: '' });

    try {
      const response = await fetch(
        `${PREDICTIVE_URL}${FETCH_TOTAL_STATION_SESSION_API}?region1=${region1}&region2=${region2}&region3=${region3}&region4=${region4}&operator=${operator}`,
        {
          method: "GET",
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          }
        }
      );

      if (!response.ok) {
        // If response is not ok, throw an error to be caught in the catch block
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("")
      dispatch({ type: 'LOAD_TOTAL_STATION_CONSUMPTION', payload: data });
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error('Error fetching station session data:', error);
      // Optionally, dispatch an error action to handle errors in the UI
      // dispatch({ type: 'LOAD_TOTAL_STATION_CONSUMPTION', payload: error.message });
    }
  };
};


export const fetchSessionTypeAction = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, role, organisation, region } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_SESSION_TYPE_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'LOAD_SESSION_TYPE', payload: data })
    } else {
      console.log("No data found from the server")
    }
  }
}

export const fetchPssFailSessionAction = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, role, organisation, region } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_TOTAL_PASS_FAIL_SESSION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'LOAD_TOTAL_SESSION', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchCityComparisionAction = (city1, city2, start_date, end_date) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, role, organisation, region } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'INSIGHT_CITY_GRAPH_LOADING', payload: '' })
    const data = await ApiHandler(CHARGING_SERVICE_URL + FETCH_CITY_COMPARISION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&city1=" + city1 + "&city2=" + city2 + "&start_date=" + start_date + "&end_date=" + end_date, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_CITY_COMPARISION', payload: data })
    } else {
      console.log("No data found from the server")
    }
  }
}

export const fetchStationComparisionAction = (station1, station2, start_date, end_date) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role, region } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  // console.log(CHARGING_SERVICE_URL + FETCH_STATION_COMPARISION_API + "?station1="+station1+"&station2="+station2+"&start_date="+start_date+"&end_date=" + end_date)
  return async dispatch => {
    dispatch({ type: 'INSIGHT_STATION_GRAPH_LOADING', payload: '' })
    const data = await ApiHandler(CHARGING_SERVICE_URL + FETCH_STATION_COMPARISION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&station1=" + station1 + "&station2=" + station2 + "&start_date=" + start_date + "&end_date=" + end_date, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_STATION_COMPARISION', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchWeekSessionTypeAction = (start, end) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role, region } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_WEEKLY_SESSION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&start=" + start + "&end=" + end, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'LOAD_WEEK_SESSION', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchMonthSessionTypeAction = (start, end) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role, region } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    // console.log(PREDICTIVE_URL + FETCH_MONTHLY_SESSION_API + "?operator=" + operator + "&start=" + start + "&end=" + end)
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_MONTHLY_SESSION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&start=" + start + "&end=" + end, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'LOAD_MONTH_SESSION', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchLifetimeEnergyRevenue = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role, region } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  // console.log(token);
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'INSIGHT_REVENUE_GRAPH_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_LIFETIME_DATA_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'LOAD_LIFETIME_ENERGY_REVENUE', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchSessionTypeByDate = (start, end) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, role, organisation, region } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_DATERANGE_SESSION_TYPE + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&start=" + start + "&end=" + end, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_DATERANGE_SESSION_TYPE', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchSessionStatusByDate = (start, end) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, role, organisation, region } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  // console.log(token)
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_DATERANGE_SESSION_STATUS + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&start=" + start + "&end=" + end, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_DATERANGE_SESSION_STATUS', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchCustomerTop5SearchResult = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token } = user;
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_CUSTOMER_TOP5_SEARCH_PREDICTION, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_CUSTOMER_TOP5_SEARCH_PREDICTION', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchCustomerAllSearchResult = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token } = user;
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_CUSTOMER_SEARCH_PREDICTION, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_CUSTOMER_ALL_SEARCH_PREDICTION', payload: data })
    } else {
      console.log("No data found from Api")
    }
  }
}

export const insightPercentageChange = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token

  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' });

    try {
      const response = await fetch(
        `${PREDICTIVE_URL}${INSIGHT_PERCENTAGE_CHANGE_API}?operator=${operator}`,
        {
          method: "GET",
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          }
        }
      );

      if (!response.ok) {
        // If response is not ok, throw an error to be caught in the catch block
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("")
      dispatch({ type: 'INSIGHT_PERCENTAGE_CHANGE', payload: data });
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error('Error fetching station session data:', error);
      // Optionally, dispatch an error action to handle errors in the UI
      // dispatch({ type: 'LOAD_TOTAL_STATION_CONSUMPTION', payload: error.message });
    }
  };
};

// fetch new Co2 insight api
export const fetchCo2CountInsightAction = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role, region } = user;
  // console.log(token)
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    // return fetch(PREDICTIVE_URL + FETCH_CO2_COUNT_INSIGHT_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
    // console.log(PREDICTIVE_URL + FETCH_CO2_COUNT_INSIGHT_API + "?operator=" + operator)
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_CO2_COUNT_INSIGHT_API + "?operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_CO2_COUNT_INSIGHT_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

// fetch  Co2 list insight api
export const fetchCo2ListInsightAction = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role, region } = user;
  // console.log(token)
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_CO2_LIST_INSIGHT_API + "?operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_CO2_LIST_INSIGHT_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}