import React, { useEffect, useState } from "react";
import PureBreadcrumbs from "../../../breadcrums";
import { Card, Col, Container, Row } from "react-bootstrap";
import ConsumedStationList from "./consumedStationList";
import OcpiMap from "./consumeLocationMap";
import { connect } from "react-redux";
import { allRoamingPartnerPartnerID, fetchLocationbyPartyId, fetchOcpiLocationCount, ocpiExternalRefreshLocationAction, refreshPartnerStationAction } from "../../../../../store/actions/ocpiAction";
import { useTranslation } from "react-i18next";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

function LocationConusmeCard(props) {
  const { t } = useTranslation();
  const [partnerName, setPartnerName] = useState("All");
  // console.log("partnerNamepartnerName,partnerName",partnerName)
  useEffect(() => {
    props.allPartnerPartyId();
    props.consumedLocationData(partnerName);
    props.ocpiLocationCount();
  }, [partnerName])

  const data = props.fetchLocationbyParty && props.fetchLocationbyParty.data;

  const handleChange = (e) => {
    let partnerid = e.target.value
    setPartnerName(partnerid)
  };

  const handleAddClick = () => {
    props.partnerRefreshLocation(partnerName)
  };
  const handleRefreshLocationCache = () => {
    props.ocpiExternalRefreshLocation()
    // console.log("refresh")
  };

  const AVAILABLE = "AVAILABLE";
  const CHARGING = "CHARGING";
  const OUTOFORDER = "OUTOFORDER";

  const percentage = (partialValue, totalValue) => {
    // console.log("percentage", Math.round((100 * partialValue) / totalValue));
    return Math.round((100 * partialValue) / totalValue);
  }
  // console.log(props.fetchOcpiLocationCountRes)

  const TotalPercentage = percentage(props.fetchOcpiLocationCountRes.total, props.fetchOcpiLocationCountRes.total)
  const AvailablePercentage = percentage(props.fetchOcpiLocationCountRes.available, props.fetchOcpiLocationCountRes.total)
  const InUsePercentage = percentage(props.fetchOcpiLocationCountRes.charging, props.fetchOcpiLocationCountRes.total)
  const OutofServicePercentage = percentage(props.fetchOcpiLocationCountRes.out_of_service, props.fetchOcpiLocationCountRes.total)


  return (
    <Container fluid className="pb-4">
      <PureBreadcrumbs />
      <Row>
        <Col className="mt-3">
          <Card className='statuscard'>
            <a href={`/dashboard/ocpi/ocpi_emsp/ocpi_consume_location/ocpiLocationStatus/${AVAILABLE}`} style={{ textDecoration: "none", color: "var(--text-primary)" }}>
              <div style={{ textAlign: "left" }}>
                <p>
                  <img src={process.env.PUBLIC_URL + '/images/insight/chargerStatus/AvailableCharger.svg'} alt="charging" />
                  &nbsp;{t("Available")}
                </p>
                <span className="count"> &nbsp;{props.fetchOcpiLocationCountRes?.available || 0} </span>
                <span style={{ float: "right", marginTop: "-20px" }}>
                  <div style={{ width: "50px" }} >
                    <CircularProgressbar value={AvailablePercentage} text={`${AvailablePercentage}%`} strokeWidth={5}
                      styles={buildStyles({
                        textColor: "#0D6EFD",
                        pathColor: "#0D6EFD",
                      })}
                    />
                  </div>
                </span>
              </div>
            </a>
          </Card>
        </Col>
        <Col className="mt-3">
          <Card className='statuscard'>
            <a href={`/dashboard/ocpi/ocpi_emsp/ocpi_consume_location/ocpiLocationStatus/${CHARGING}`} style={{ textDecoration: "none", color: "var(--text-primary)" }}>
              <div style={{ textAlign: "left" }}>
                <p>
                  <img src={process.env.PUBLIC_URL + '/images/insight/chargerStatus/InUseCharger.svg'} alt="charging" />
                  &nbsp;{t("Charging")}
                </p>
                <span className="count"> &nbsp;{props.fetchOcpiLocationCountRes.charging || 0} </span>
                <span style={{ float: "right", marginTop: "-20px" }}>
                  <div style={{ width: "50px" }} >
                    <CircularProgressbar value={InUsePercentage} text={`${InUsePercentage}%`} strokeWidth={5}
                      styles={buildStyles({
                        textColor: "#0D6EFD",
                        pathColor: "#0D6EFD",
                      })}
                    />
                  </div>
                </span>
              </div>
            </a>
          </Card>
        </Col>
        <Col className="mt-3">
          <Card className='statuscard'>
            <a href={`/dashboard/ocpi/ocpi_emsp/ocpi_consume_location/ocpiLocationStatus/${OUTOFORDER}`} style={{ textDecoration: "none", color: "var(--text-primary)" }}>
              <div style={{ textAlign: "left" }}>
                <p>
                  <img src={process.env.PUBLIC_URL + '/images/insight/chargerStatus/OutofOrderCharger.svg'} alt="charging" />
                  &nbsp;{t("Out of order")}
                </p>
                <span className="count"> &nbsp;{props.fetchOcpiLocationCountRes.out_of_service || 0} </span>

                <span style={{ float: "right", marginTop: "-20px" }}>
                  <div style={{ width: "50px" }} >
                    <CircularProgressbar value={OutofServicePercentage} text={`${OutofServicePercentage}%`} strokeWidth={5}
                      styles={buildStyles({
                        textColor: "#0D6EFD",
                        pathColor: "#0D6EFD",
                      })}
                    />
                  </div>
                </span>
              </div>
            </a>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
        <label className="ml-3 d-flex align-items-center mb-0" style={{ fontSize: "larger" }} htmlFor="partnerNameDropdown">{t("Partner name")} :</label>
        <Col lg={4}>
          <select className='form-control form-select' id="partnerNameDropdown" onChange={handleChange}>
            <option value={"All"}>{t("All")}</option>
            {props.partyidlist && props.partyidlist.data && props.partyidlist.data?.map((item) =>
              <option key={item.id} value={item.party_id}>{item.partner_name}</option>
            )}
          </select>
        </Col>
        {partnerName !== "All" ?
          <Col className="col-lg-6 col-sm-12 text-right">
            <button
              className="ocpi_buttons btn"
              onClick={handleAddClick}
            >
              Pull location data : {partnerName}
              <img
                className="refresh_icon"
                src={process.env.PUBLIC_URL + '/images/insight/refresh.svg'}
                alt=""
              />
            </button>
          </Col>
          :
          <Col className="col-lg-6 col-sm-12 text-right">
            <button
              className="btn ocpi_buttons btn"
              onClick={handleRefreshLocationCache}
            >
              Refresh Locations Cache
              <img
                className="refresh_icon"
                src={process.env.PUBLIC_URL + '/images/insight/refresh.svg'}
                alt=""
              />
            </button>
          </Col>
        }
      </Row>

      {/* <OcpiMap {...props} /> */}

      <Row >
        <Col className="mt-4">
          <Card className="">
            {props.loading ? <h3 style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "250px",
            }}>Loading data...</h3> :
              data && data.length > 0 ? (
                <div>
                  <ConsumedStationList {...props} data={data} />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                > <h4>{t("No Data Found")}</h4>
                </div>
              )}
          </Card>
        </Col>
      </Row>

    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    loading: state.ocpi.isLoading,
    partyidlist: state.ocpi.allpartyid,
    fetchLocationbyParty: state.ocpi.fetchLocationByPartyid,
    prtnerLocationRefresh: state.ocpi.locationRefresh,
    fetchOcpiLocationCountRes: state.ocpi.fetchOcpiLocationCount,
    ocpiExternalRefreshLocationRes: state.ocpi.ocpiExternalRefreshLocation,
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    allPartnerPartyId: () => dispatch(allRoamingPartnerPartnerID()),
    consumedLocationData: (partner_name) => dispatch(fetchLocationbyPartyId(partner_name)),
    partnerRefreshLocation: (partner_id) => dispatch(refreshPartnerStationAction(partner_id)),
    ocpiLocationCount: () => dispatch(fetchOcpiLocationCount()),
    ocpiExternalRefreshLocation: () => dispatch(ocpiExternalRefreshLocationAction()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationConusmeCard);
