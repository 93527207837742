import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Bounce, toast, ToastContainer } from 'react-toastify'
import PureBreadcrumbs from "../../breadcrums";
import { connect } from 'react-redux';
import { FetchSingleVehicle, UpdateSingleVehicle } from '../../../../store/actions/fleetAction';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetchAllMaker, fetchAllModel } from '../../../../store/actions/mainAction';

function UpdateVehicle(props) {
    const { t } = useTranslation;
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [data, setData] = useState([]);
    const [maxDate, setMaxDate] = useState("");
    const { id } = useParams();

    const vehicleSchema = Yup.object().shape({
        vin: Yup.string().required("VIN number is required"),
        fleet_name: Yup.string().required("Fleet name is required"),
        vehicle_make: Yup.string().required("Vehicle Manufacturer is required"),
        vehicle_model: Yup.string().required("Vehicle Model is required"),
        registration_date: Yup.string().required("Registration Date is required"),
        vehicle_type: Yup.string().required("Vehicle type is required"),
        v_owner: Yup.string().required("Vehicle Owner is required"),
    });
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors },
    } = useForm({ resolver: yupResolver(vehicleSchema) });

    useEffect(() => {
        const today = new Date().toISOString().split("T")[0];
        setMaxDate(today);
        props.FetchSingleVehicleApi(id)
        props.fetchAllMakerApi()
    }, [])
    useEffect(() => {
        setData(props.FetchSingleVehicleRes.vehicle)
    }, [props.FetchSingleVehicleRes])

    useEffect(() => {
        if (props.UpdateVehicleApiRes.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Vehicle Has Been Updated', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.UpdateVehicleApiRes.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.save.error}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.UpdateVehicleApiRes]);

    const handlMakeChange = (e) => {
        const selectedOption = e.target.options[e.target.selectedIndex];
        const value = selectedOption.getAttribute('value');;
        const image = selectedOption.getAttribute('image_url');
        setValue("vehicle_make_image", image);
        setValue("vehicle_model", "");
        props.fetchAllModalApi(value);

    };
    console.log(props.UpdateVehicleApiRes, "res");
    const handleKeyDown = (e) => {
        e.preventDefault();
    };
    useEffect(() => {
        if (data) {
            setTimeout(() => {
                const { vin, fleet_name, vehicle_make, vehicle_model, vehicle_type, registration_date, v_owner, vehicle_make_image } = data;
                setValue("vin", vin, { shouldValidate: true });
                setValue("fleet_name", fleet_name, { shouldValidate: true });
                setValue("vehicle_make", vehicle_make, { shouldValidate: true });
                setValue("vehicle_model", vehicle_model, { shouldValidate: true });
                setValue("vehicle_type", vehicle_type, { shouldValidate: true });
                setValue("registration_date", registration_date, { shouldValidate: true });
                setValue("v_owner", v_owner, { shouldValidate: true });
                setValue("vehicle_make_image", vehicle_make_image, { shouldValidate: true });
            }, 1000);
        }
        props.fetchAllModalApi(data?.vehicle_make)
    }, [data], setValue)

    const onSubmit = async (data) => {
        data.id = id
        props.UpdateVehicleApi(data)
    };

    return (

        <Container fluid>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={1}></Col>
                <Col xs={12} md={10}>
                    <br />
                    <Card className="tariffcard">
                        <Card.Title>Update Vehicle</Card.Title>
                        <Card.Body>
                            <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                <div className="form-floating">
                                    <label>
                                        Fleet Name <span className="mandatory-field">*</span>
                                    </label>
                                    <input className='form-control' value={data?.fleet_name} disabled />
                                </div>
                                <br />
                                <div className="form-floating">
                                    <label>
                                        VIN <span className="mandatory-field">*</span>
                                    </label>
                                    <input className='form-control' value={data?.vin} disabled />
                                </div>
                                <br />
                                <div className="form-floating">
                                    <label>Vehicle Owner Name <span className='mandatory-field'>*</span></label>
                                    <input type="text" placeholder="Enter Owner Name" className={`form-select form-control ${errors.v_owner ? "is-invalid" : ""}`} {...register("v_owner")} />
                                    <div className="invalid-feedback">{errors.v_owner?.message}</div>
                                </div>
                                <br />
                                <div className="form-floating">
                                    <label>Manufacture Name  <span className='mandatory-field'>*</span></label>
                                    <select
                                        className={`form-select form-control ${errors.vehicle_make ? "is-invalid" : ""}`}
                                        name="fleet_name"
                                        id="floatingSelectGridfleet_name"
                                        {...register("vehicle_make")}
                                        onChange={(e) => handlMakeChange(e)}
                                    >
                                        <option value="">Select Manufacture</option>
                                        {props.fetchAllMakerRes &&
                                            props.fetchAllMakerRes.make?.map((v, k) => (
                                                <option key={k} value={v.make_code
                                                } image_url={v.image_url}>
                                                    {v.make_name}
                                                </option>
                                            ))}
                                    </select>
                                    <div className="invalid-feedback">
                                        {errors.vehicle_make?.message}
                                    </div>
                                </div>
                                <br />
                                <div className="form-floating">
                                    <label>Vehicle Model  <span className='mandatory-field'>*</span></label>
                                    <select
                                        className={`form-select form-control ${errors.vehicle_model ? "is-invalid" : ""}`}
                                        name="vehicle_model"
                                        id="floatingSelectGridfleet_name"
                                        {...register("vehicle_model")}
                                    >
                                        <option value="">Select Vehicle Model</option>
                                        {props.fetchAllModalRes &&
                                            props.fetchAllModalRes.model?.map((v, k) => (
                                                <option key={k} value={v.
                                                    model_name
                                                }>
                                                    {v.model_name}
                                                </option>
                                            ))}
                                    </select>
                                    <div className="invalid-feedback">
                                        {errors.vehicle_model?.message}
                                    </div>
                                </div>
                                <br />
                                <div className="form-floating">
                                    <label>Vehicle Registration Date<span className='mandatory-field'>*</span></label>
                                    <input type="date" placeholder="Enter Vehicle Model" max={maxDate} className={`form-select form-control ${errors.registration_date ? "is-invalid" : ""}`} {...register("registration_date")} onKeyDown={handleKeyDown} />
                                    <div className="invalid-feedback">{errors.registration_date?.message}</div>
                                </div>
                                <br />
                                <div className="form-floating">
                                    <label>Vehicle Type  <span className='mandatory-field'>*</span></label>
                                    <select
                                        className={`form-select form-control ${errors.vehicle_type ? "is-invalid" : ""}`}
                                        name="vehicle_type"
                                        {...register("vehicle_type")}
                                    >
                                        <option value={""}>Select Vehicle Type</option>
                                        <option value={"2 Wheeler"}>2 Wheeler</option>
                                        <option value={"3 Wheeler"}>3 Wheeler</option>
                                        <option value={"Heavy vehicle"}>Heavy vehicle</option>
                                        <option value={"Light Vehicle"}>Light Vehicle</option>
                                    </select>
                                    <div className="invalid-feedback">
                                        {errors.vehicle_type?.message}
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div>
                                    <Row className="d-flex justify-content-between">
                                        <Col lg={5} md={3} sm={4} xs={12} className="mt-2">
                                            <button
                                                className="lgn-btn btn"
                                                type="button"
                                                onClick={() => reset()}
                                            >
                                                Reset
                                            </button>
                                        </Col>
                                        <br />
                                        <Col lg={5} md={3} sm={4} xs={12} className="d-md-flex justify-content-end mt-2">
                                            {props.loading ?
                                                <button className="btn lgn-btn" type="button" disabled>
                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                    &nbsp;
                                                    {t("Saving...")}
                                                </button>
                                                :
                                                <button
                                                    className="lgn-btn btn"
                                                    type="submit"
                                                    onClick={handleSubmit(onSubmit)}
                                                >
                                                    SUBMIT
                                                </button>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                                <br />
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Container >
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.campaign.isLoading,
        FetchSingleVehicleRes: state.fleet.fetch_single_vehicle,
        fetchAllMakerRes: state.main.allmaker,
        fetchAllModalRes: state.main.allmodel,
        UpdateVehicleApiRes: state.fleet.update_single_vehicle
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        FetchSingleVehicleApi: (id) => dispatch(FetchSingleVehicle(id)),
        fetchAllMakerApi: () => dispatch(fetchAllMaker()),
        fetchAllModalApi: (make_code) => dispatch(fetchAllModel(make_code)),
        UpdateVehicleApi: (data) => dispatch(UpdateSingleVehicle(data))
    };

};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateVehicle);