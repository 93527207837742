import React, { useMemo, useState } from 'react'
import { useEffect } from "react";
import { Card, Row, Col, Container, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PureBreadcrumbs from "../../breadcrums";
import "../ocpi.css"
import { useTranslation } from 'react-i18next';
import { allRoamingPartnerPartnerID, fetchExternalViewSingleSessionAction, fetchExternalViewSingleSessionPartnerAction } from '../../../../store/actions/ocpiAction';
import moment from 'moment';
import ReactSelect from "react-select";
import Table from '../../reacttable/table';


const ViewCdrReport = (props) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('Self');
    const [selectedOrg, setSelectedOrg] = useState("");
    const { partyidlist } = props;
    const firstRecord = partyidlist?.data?.[0] || {};
    const { party_id = null } = firstRecord;

    useEffect(() => {
        props.allPartnerPartyId();
        setSelectedOrg(party_id)
        if (activeTab === 'Self') {
            // props.fetchSessionSelfData(selectedOrg) // Fetch Self data
        } else if (activeTab === 'Partner') {
            // props.fetchSessionPartnerData(selectedOrg) // Fetch partner data
        }
    }, [activeTab, party_id]);

    const selfData = (props.fetchExternalViewSingleSessionSelfDataRes && props.fetchExternalViewSingleSessionSelfDataRes.data) || {};

    const partnerData = (props.fetchExternalViewSingleSessionPartnerDataRes && props.fetchExternalViewSingleSessionPartnerDataRes.data) || {};
    const data = activeTab === 'Self' ? selfData : partnerData;

    // console.log("data", data);

    const options = useMemo(() => {
        return partyidlist?.data?.map(item => ({
            value: item.party_id,
            label: item.partner_name,
        })) || [];
    }, [partyidlist]);

    const handleOrgChange = (e) => {
        setSelectedOrg(e.value);
    };

    const columns = useMemo(
        () => [
            { Header: "ID", accessor: "charging_id" },
            { Header: "First Name", accessor: "firstname" },
            { Header: "Last Name", accessor: "lastname" },
            { Header: "User Name", accessor: "userid" },
            { Header: "EVSE UID", accessor: "evse_uid" },
            { Header: "Location", accessor: "station_name", id: "station_name" },
            {
                Header: "Start Date",
                accessor: "date",
                Cell: (item) => {
                    const value = item.cell;
                    // console.log(item)
                    return (
                        <>
                            <p>
                                {value ? value : 'NA'}
                            </p>
                        </>
                    )
                }
            },
            {
                Header: "Start Time",
                accessor: "start_date_time",
                Cell: (item) => {
                    const value = item.cell;
                    // console.log(item)
                    return (
                        <>
                            <p>
                                {value ? moment(value).format('HH:mm:ss') : 'NA'}
                            </p>
                        </>
                    )

                }
            },
            {
                Header: "End Time",
                accessor: "end_date_time",
                Cell: (item) => {
                    const value = item.cell;
                    return (
                        <>
                            <p>
                                {value ? moment(value).format('HH:mm:ss') : 'NA'}
                            </p>
                        </>
                    )

                }
            },
            { Header: "Connector ID", accessor: "connector_id" },
            { Header: "kWh", accessor: "kwh" },
            { Header: "Cost Excluding Vat", accessor: "excl_vat" },
            { Header: "Cost Including Vat", accessor: "incl_vat" },
            { Header: "Status", accessor: "status" },
            {
                Header: "Actions",
                id: "viewSingle",
                accessor: "session_id",
                Cell: (cell) => {
                    const { item } = cell
                    // console.log(item)
                    const { session_id, cdr_id, status, party_id } = item
                    return (
                        <div>
                            <button
                                className="btn lgn-btn mr-2"
                                onClick={() => window.location.href = `/dashboard/viewSingleOcpiSessionReport/${session_id}/${party_id}`}
                                title="View Single Session"
                                style={{ height: "auto", width: "25%" }}
                            >
                                View Single Session
                            </button>
                            <button
                                className="btn lgn-btn my-2 mr-2"
                                disabled={status !== 'COMPLETED'}
                                onClick={() => window.location.href = `/dashboard/viewSingleOcpiCdrsReport/${cdr_id}/${party_id}`}
                                title="View Single CDR's"
                                style={{ height: "auto", width: "25%" }}
                            >
                                View Single CDR's
                            </button>
                        </div>
                    );
                },
            }

        ],
        []
    );

    console.log("selectedOrg", selectedOrg)

    const renderForm = (isPartner) => (
        <Row className="mt-2">
            <Col>
                <Card className="customercard">
                    <Card.Header>
                        <Row className="align-items-center my-3">
                            <Col md={6} sm={12} className="font-weight-bold text-left">
                                {!isPartner ? 'Self CDRs Report' : "Partner CDRs Report"}
                            </Col>
                            <Col md={6} sm={12}>
                                <label>Select Party ID</label>
                                <ReactSelect
                                    options={options || []}
                                    placeholder={selectedOrg}
                                    value={options?.find(option => option?.value === selectedOrg)}
                                    onChange={handleOrgChange}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: 'var(--select-control-background-color-white)', // White
                                            borderColor: state.isFocused ? 'var(--select-control-border-color-gray)' : 'var(--select-control-border-color-gray)', // Gray
                                            boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',
                                            color: 'var(--select-control-text-color-black)', // White text color for the control
                                            // height: 'calc(1.5em + 0.75rem + 2px)',
                                            // minHeight: 'calc(1.5em + 0.75rem + 2px)',
                                            '&:hover': {
                                                borderColor: 'var(--select-control-hover-border-color-gray)', // Gray
                                            },
                                        }),
                                        valueContainer: (baseStyles) => ({
                                            ...baseStyles,
                                            // height: 'calc(1.5em + 0.75rem + 2px)',
                                            padding: '0 0.75rem',
                                            color: 'var(--select-control-text-color-black)', // White text color
                                            width: '11rem',
                                        }),
                                        placeholder: (baseStyles) => ({
                                            ...baseStyles,
                                            whiteSpace: 'nowrap',
                                            color: 'var(--select-placeholder-color-gray)', // Gray placeholder color
                                        }),
                                        input: (base) => ({
                                            ...base,
                                            color: 'var(--select-control-text-color-black)', // White text color
                                            border: 'none',
                                            boxShadow: 'none',
                                            '& input': {
                                                border: 'none !important',
                                                color: 'var(--select-control-text-color-black)', // White text color
                                            },
                                        }),
                                        menu: (base) => ({
                                            ...base,
                                            backgroundColor: 'var(--select-menu-background-color-black)', // Black
                                            color: 'var(--select-menu-text-color-white)', // White text color
                                        }),
                                        option: (base, state) => ({
                                            ...base,
                                            backgroundColor: state.isFocused
                                                ? 'var(--select-option-hover-background-color-gray)' // Gray on hover
                                                : 'var(--select-menu-background-color-black)', // Black background
                                            color: state.isSelected
                                                ? 'var(--select-option-selected-text-color-white)' // White if selected
                                                : 'var(--select-menu-text-color-white)', // White otherwise
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: 'var(--select-option-hover-background-color-gray)', // Gray on hover
                                            },
                                        }),
                                        multiValue: (base) => ({
                                            ...base,
                                            backgroundColor: 'var(--multi-value-background-color-gray)', // Gray
                                        }),
                                        multiValueLabel: (base) => ({
                                            ...base,
                                            color: 'var(--multi-value-text-color-white)', // White text color
                                        }),
                                        multiValueRemove: (base) => ({
                                            ...base,
                                            color: 'var(--multi-value-text-color-white)', // White text color
                                            ':hover': {
                                                backgroundColor: 'var(--select-option-hover-background-color-gray)', // Gray on hover
                                                color: 'var(--multi-value-text-color-white)', // White text color
                                            },
                                        }),
                                        clearIndicator: (base) => ({
                                            ...base,
                                            color: 'var(--clear-indicator-color-white)', // White
                                            ':hover': {
                                                color: 'var(--clear-indicator-color-white)', // White on hover
                                            },
                                            cursor: 'pointer'
                                        }),
                                        singleValue: (base) => ({
                                            ...base,
                                            color: 'var(--select-control-text-color-black)', // White text color
                                        }),
                                    }}
                                />
                            </Col>
                            <Col md={2} sm={12} className="d-flex justify-content-end align-items-center">
                                {/* <div className="mt-1 p-3">
                                    {props.allList && props.allList.data ? (
                                        <CSVLink
                                            data={processedData}
                                            headers={headers}
                                            filename={`${org}report.csv`}
                                            target="_blank"
                                            style={{
                                                textDecoration: 'none',
                                                color: '#007bff',
                                            }}
                                        >
                                            <i className="fas fa-2x fa-file-csv" style={{ color: '#28a745' }} title="Download CSV"></i>
                                        </CSVLink>
                                    ) : (
                                        <i className="fas fa-2x fa-file-csv" style={{ color: '#dc3545' }} title="CSV Unavailable"></i>
                                    )}
                                </div> */}
                                {/* <div className="p-3">
                                    <TransactionReportPdf
                                        {...props}
                                        processedData={processedData}
                                        org={org}
                                    />
                                </div> */}
                            </Col>
                        </Row>
                    </Card.Header>
                    {props.loading ? ( // Show the spinner when loading
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: '400px', // Adjust height as needed
                            }}
                        >
                            <Spinner animation="border" role="status">
                                {/* <span className="visually-hidden"></span> */}
                            </Spinner>
                        </div>
                    ) : data && data.length > 0 ? (
                        // <CustomOcpiPaginationTable
                        //     data={data}
                        //     allData={processedData}
                        //     pageCount={pageCount}
                        //     columns={columns}
                        //     search={search}
                        //     setSearch={setSearch}
                        //     currentPage={currentPage}
                        //     setCurrentPage={setCurrentPage}
                        //     pageSize={pageSize}
                        //     setPageSize={setPageSize}
                        //     filteredData={filteredData}
                        //     setFilteredData={setFilteredData}
                        //     initialVisibleColumns={initialVisibleColumns}
                        //     t={t}
                        // />
                        <Table data={data} columns={columns}></Table>
                    ) : (
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "400px" }}>
                            <h3 className="loader">{t("No data found")}</h3>
                        </div>
                    )}
                </Card>
            </Col>
        </Row >

    );

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />

            <Row className="mt-3">
                <Col xs={12}>
                    <div style={{
                        border: "1px solid #d9d9d9",
                        borderRadius: "50px",
                        padding: "0px",
                        backgroundColor: "#f3f3f3", // Light background for the switch container
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "20%",
                        margin: "0 auto",
                    }}>
                        <button
                            onClick={() => setActiveTab('Self')}
                            className={`tab-button ${activeTab === 'Self' ? 'active' : ''}`}
                            style={{
                                backgroundColor: activeTab === 'Self' ? '#000000' : '#f3f3f3',
                                color: activeTab === 'Self' ? '#f3f3f3' : '#000000',
                                border: "none",
                                padding: '10px 20px',
                                borderRadius: "50px",
                                fontWeight: "bold",
                                fontSize: "16px",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                width: "55%",
                            }}
                        >
                            Self
                        </button>
                        <button
                            onClick={() => setActiveTab('Partner')}
                            className={`tab-button ${activeTab === 'Partner' ? 'active' : ''}`}
                            style={{
                                backgroundColor: activeTab === 'Partner' ? '#000000' : '#f3f3f3',
                                color: activeTab === 'Partner' ? '#f3f3f3' : '#000000',
                                border: "none",
                                padding: '10px 20px',
                                borderRadius: "50px",
                                fontWeight: "bold",
                                fontSize: "16px",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                width: "55%",
                            }}
                        >
                            Partner
                        </button>
                    </div>
                </Col>
            </Row>
            {props.loading ? (
                <Row >
                    <Col lg={12} className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                    </Col>
                </Row>
            ) : (
                <>
                    {activeTab === 'Self' && renderForm(false)}
                    {activeTab === 'Partner' && renderForm(true)}
                </>
            )}

        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.ocpi.isLoading,
        partyidlist: state.ocpi.allpartyid,
        // fetchExternalViewSingleSessionSelfDataRes: state.ocpi.fetchExternalViewSingleSessionData,
        // fetchExternalViewSingleSessionPartnerDataRes: state.ocpi.fetchExternalViewSingleSessionPartnerData,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        allPartnerPartyId: () => dispatch(allRoamingPartnerPartnerID()),
        // fetchSessionSelfData: (id, party_id) => dispatch(fetchExternalViewSingleSessionAction(id, party_id)),
        // fetchSessionPartnerData: (id, party_id) => dispatch(fetchExternalViewSingleSessionPartnerAction(id, party_id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewCdrReport)