import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import PureBreadcrumbs from "../../breadcrums";
import { Container, Card, Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Table from '../../reacttable/table';
import { FaEdit, FaEye } from 'react-icons/fa';
import { TiTick } from 'react-icons/ti';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { activateSubscriptionApi, deactivateSubscriptionApi, fetchAllSubscriptionApi } from '../../../../store/actions/walletActions';


export const ViewSubscription = (props) => {
    const { t } = useTranslation();
    useEffect(() => {
        props.fetchAll();
    }, [])
    const data = props.fetchAllRes && props.fetchAllRes.data;
    // console.log(data);
    const total = data && data.length;
    const active = data && data.filter((item) => item.status === "Active").length;
    const inActive = data && data.filter((item) => item.status === "Inactive").length;
    const draft = data && data.filter((item) => item.status === "Draft").length;

    const activateSubscription = (sub_name, sub_type, duration) => {
        // console.log(sub_name);
        // console.log(sub_type);
        // console.log(duration);
        props.activate(sub_name, sub_type, duration);
    };

    if (props.activateRes.statuscode === 200) {
        window.location.reload();
    }

    const deactivateSubscription = (e) => {
        // console.log(e);
        props.deactivate(e);
    };

    if (props.deactivateRes.statuscode === 200) {
        window.location.reload();
    }

    const columns = useMemo(
        () => [
            {
                Header: "Subscription Name",
                id: "sub_name1",
                accessor: "sub_name",
            },
            {
                Header: "Subscription Type",
                accessor: "sub_type",
            },
            {
                Header: "Subscription Value",
                accessor: "sub_value",
            },
            {
                Header: "Subscription Duration",
                accessor: "sub_duration",
            },
            {
                Header: "Recharge Amount",
                accessor: "recharge_amount",
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: (status) => {
                    // console.log(publish.value)
                    return (
                        <div>
                            <span>
                                {status.value === "Active" ? (
                                    <span className="text-success">{t("Active")}</span>
                                ) : status.value === "Inactive" ? (
                                    <span className="text-danger">{t("Inactive")}</span>
                                ) : (
                                    <span className="text-warning">{t("Draft")}</span>
                                )}
                            </span>
                        </div>
                    );
                },
            },
            {
                Header: "Actions",
                accessor: "sub_name",
                Cell: ({ row }) => {
                    const { original } = row;
                    const status = original.status;
                    const sub_name = original.sub_name;
                    const sub_type = original.sub_type;
                    const duration = original.sub_duration;
                    // console.log(sub_name)
                    return (
                        <div>
                            <span>
                                <a
                                    href={`/dashboard/view_subscription/viewSingleSubscription/${sub_name}`}
                                    rel="tooltip"
                                    title="View"
                                >
                                    <FaEye />
                                </a>
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {status && status === "Draft" ?
                                <span>
                                    <a
                                        href={`/dashboard/view_subscription/updateSubscription/${sub_name}`}
                                        rel="tooltip"
                                        title="Edit"
                                    >
                                        <FaEdit />
                                    </a>
                                </span>
                                :
                                ""
                            }
                            &nbsp;&nbsp;  &nbsp;&nbsp;
                            <span style={{ cursor: "pointer" }}>
                                <TiTick
                                    onClick={() => activateSubscription(sub_name, sub_type, duration)}
                                    title="Activate"
                                />
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span style={{ cursor: "pointer" }}>
                                <AiOutlineCloseCircle
                                    onClick={() => deactivateSubscription(sub_name, sub_type, duration)}
                                    title="De-Activate"
                                />
                            </span>
                        </div>
                    );
                },
            },

        ],
        []
    );

    return (
        <Container fluid className="pb-4">
            <PureBreadcrumbs />
            <Row className="mt-2">
                <Col className="col-md-3 col-12">
                    <Card className="customercard card-custom total-rules">
                        <div className="diagonal-line"></div>
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "16px" }}>{t("TOTAL SUBSCRIPTIONS")}</Card.Text>
                            <Card.Title>
                                <b>{total ? total : 0}</b>
                            </Card.Title>
                        </div>
                    </Card>
                    <br />
                </Col>
                <Col className="col-md-3 col-12">
                    <Card className="customercard card-custom total-active-rules">
                        <div className="diagonal-line"></div>
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "16px" }}>{t("TOTAL ACTIVE SUBSCRIPTIONS")}</Card.Text>
                            <Card.Title>
                                <b>{active ? active : 0}</b>
                            </Card.Title>
                        </div>
                    </Card>
                    <br />
                </Col>
                <Col className="col-md-3 col-12">
                    <Card className="customercard card-custom total-inactive">
                        <div className="diagonal-line"></div>
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "16px" }}>{t("TOTAL INACTIVE SUBSCRIPTIONS")}</Card.Text>
                            <Card.Title>
                                <b>{inActive ? inActive : 0}</b>
                            </Card.Title>
                        </div>
                    </Card>
                    <br />
                </Col>
                <Col className="col-md-3 col-12">
                    <Card className="customercard card-custom total-draft">
                        <div className="diagonal-line"></div>
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL DRAFT SUBSCRIPTIONS")}</Card.Text>
                            <Card.Title>
                                <b>{draft ? draft : 0}</b>
                            </Card.Title>
                        </div>
                    </Card>
                    <br />
                </Col>
            </Row>


            <Row className="mt-2">
                <Col lg={12}>
                    <Card className="mt-2 customercard ">
                        <div className="table-header">{t("View Subscription")}</div>

                        <Card.Body>
                            <div className="evse">
                                {props.loading ? <h3 style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "250px",
                                }}>Loading data...</h3> :
                                    props.fetchAllRes && props.fetchAllRes.data != null ? (
                                        <div>
                                            <Table data={data} columns={columns} ></Table>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: "250px",
                                            }}
                                        >
                                            <h3>{t("No Data Found")}</h3>
                                        </div>
                                    )}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.wallet.isLoading,
        fetchAllRes: state.wallet.fetchAllSub,
        activateRes: state.wallet.activateSub,
        deactivateRes: state.wallet.deactivateSub,

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAll: () => dispatch(fetchAllSubscriptionApi()),
        activate: (sub_name) => dispatch(activateSubscriptionApi(sub_name)),
        deactivate: (sub_name) => dispatch(deactivateSubscriptionApi(sub_name)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewSubscription)