import { UPDATE_TICKET_API, FETCH_SINGLE_TICKET, FETCH_ALL_TICKET, TICKET_SERVICE_URL, CREATE_TICKET } from "../../components/config/config";
import { ApiHandler } from "./apiHandler";

export const updateTicket = (formData) => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_TICKET', payload: '' })
    const data = await ApiHandler(TICKET_SERVICE_URL + UPDATE_TICKET_API, {
      method: "POST",
      headers: {
        'Authorization': bearer,
      },
      body: formData,
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'UPDATE_TICKET_REDUCER', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const createNewTicket = (formData) => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_TICKET', payload: '' })
    const data = await ApiHandler(TICKET_SERVICE_URL + CREATE_TICKET, {
      method: "POST",
      headers: {
        'Authorization': bearer,
      },
      body: formData,
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'SAVE_NEW_TICKET', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchAllTicket = () => {
  const { token, role, userid, organisation } = JSON.parse(localStorage.getItem('user'));
  // console.log(organisation)
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_TICKET', payload: '' })
    const data = await ApiHandler(TICKET_SERVICE_URL + FETCH_ALL_TICKET + "?role=" + role + "&assignedto=" + userid, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_ALL_TICKET', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const singleViewTicketAction = (ticket_id) => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  // console.log(TICKET_SERVICE_URL + FETCH_SINGLE_TICKET + "?ticket_id=" + ticket_id)
  return async dispatch => {
    dispatch({ type: 'LOAD_TICKET', payload: '' })
    const data = await ApiHandler(TICKET_SERVICE_URL + FETCH_SINGLE_TICKET + "?ticket_id=" + ticket_id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_SINGLE_VIEW_TICKET', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}