import React from "react";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";



const SearchGraph = (props) => {
  const { t } = useTranslation();
  const dataSearch = props.top5Search.data && props.top5Search.data.map((en) => en.Search);
  // console.log("dataSearch", dataSearch);
  const FlatData = dataSearch && dataSearch.flat();
  const searchCount = FlatData && FlatData.map((data) => data.search_count);
  const state = FlatData && FlatData.map((data) => data.state);

  const gradientColors = [
    // "rgba(25, 19, 132, 0.7)",
    "rgba(255, 159, 64, 0.7)",
    "rgba(235, 205, 86, 1)",
    "rgba(75, 192, 192, 0.9)",
    "rgba(54, 162, 235, 0.7)",
  ];

  return (
    <Card>
      <span className="dashboardheader text-center mb-1">Top Searches</span>
      <div className="">

        {props.loading ? <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "340px",
        }}>
          <h3>Loading Data...</h3>
        </div> :
          FlatData && FlatData.length > 0 ? (
            <Chart
              options={{
                chart: {
                  id: "apexchart-example",
                },
                xaxis: {
                  categories: state,

                },
                fill: {
                  colors: gradientColors,
                  gradient: {
                    type: "vertical",
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 100],
                  },
                },
              }}
              series={[
                {
                  name: "count",
                  data: searchCount,
                },
              ]}
              type="bar"
            />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "340px",
              }}
            >
              <h3 className="loader">{t("No data found")}</h3>
            </div>
          )}

      </div>
    </Card>
  );
};

export default SearchGraph;
