import { OCPI_URL, SAVE_ROAMING_PARTNER, VIEW_ALL_ROAMING_PARTNER, FETCH_PARTNER_TARIFF_BY_ID_API, VIEW_SINGLE_ROAMING_PARTNER, UPDATE_ROAMING_PARTNER, FETCH_CONSUMED_LOCATION_API, FETCH_SINGLE_CONSUMED_LOCATION_API, ACTIVATE_ROAMING_API, DEACTIVATE_ROAMING_API, FETCH_PARTNER_TARIFF_API, FETCH_PARTNER_PARTY_ID_API, REFRESH_PARTNER_STATION_API, REFRESH_PARTNER_TARIFF_API, GENERATE_TOKEN_API, FETCH_OCPI_STATION_LIST, FETCH_SINGLE_OCPI_STATION_LIST, FETCH_EXTERNAL_TRANSACTION_LIST_API, FETCH_INTERNAL_TRANSACTION_LIST_API, FETCH_EMSP_PARTNER_LIST_API, FETCH_ALL_EMSP_PARTY_ID_API, FETCH_OCPI_LOCATION_STATUS_API, FETCH_OCPI_LOCATION_COUNT_API, FETCH_INTERNAL_TRANSACTION_PARTNER_DROPDOWN_URL, OCPI_EXTERNAL_REFRESH_LOCATION_CACHE_API, OCPI_EXTERNAL_VIEW_SINGLE_PARTNER_TOKEN_API, OCPI_EXTERNAL_VIEW_SINGLE_PARTNER_API, OCPI_EXTERNAL_VIEW_PARTNER_TARIFF_PARTNER_DATA_API, OCPI_EXTERNAL_VIEW_STATION_PARTNER_SERVER_API, OCPI_EXTERNAL_VIEW_SINGLE_SESSION_API, OCPI_EXTERNAL_VIEW_SINGLE_SESSION_SELF_DATA_API, OCPI_EXTERNAL_VIEW_SINGLE_SESSION_PARTNER_DATA_API, OCPI_EXTERNAL_VIEW_SINGLE_CDR_SELF_DATA_API, OCPI_EXTERNAL_VIEW_SINGLE_CDR_PARTNER_DATA_API } from "../../components/config/config";
import { ApiHandler } from "./apiHandler";

export const saveRoamingPartner = (data) => {
    const { partner_name, party_id, url, token_a } = data
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(data)
    const bearer = 'base ' + token
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + SAVE_ROAMING_PARTNER, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "partner_name": partner_name,
                'url': url,
                "party_id": party_id,
                // 'role': role,
                // "organisation": organisation,
                "token_a": token_a
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'SAVE_ROAMING_PARTNER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const allRoamingPartner = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + VIEW_ALL_ROAMING_PARTNER + "?role=" + role + "&organisation=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'VIEW_ALL_ROAMING_PARTNER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const allRoamingPartnerPartnerID = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + FETCH_PARTNER_PARTY_ID_API, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_PARTNER_ID', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const singleRoamingPartner = (party_id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    // console.log("efEEWFEWFEW", OCPI_URL + VIEW_SINGLE_ROAMING_PARTNER + "?party_id=" + party_id)
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + VIEW_SINGLE_ROAMING_PARTNER + "?party_id=" + party_id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'VIEW_SINGLE_ROAMING_PARTNER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// External >>> view partner >>> single partner >>> self ( tokens )
export const fetchExternalViewSinglePartnerTokensAction = (party_id, tokenC) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    // console.log("efEEWFEWFEW", OCPI_URL + VIEW_SINGLE_ROAMING_PARTNER + "?party_id=" + party_id)
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + OCPI_EXTERNAL_VIEW_SINGLE_PARTNER_TOKEN_API + "?party_id=" + party_id + "&token_c=" + tokenC, {
            // const data = await ApiHandler(`http://192.168.1.32:7041/ocpi/secure/api/v1/get/cpo/credential/tokens?party_id=${party_id}&token_c=${tokenC}`, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("OCPI_EXTERNAL_VIEW_SINGLE_PARTNER_TOKEN_REDUCER", data)
            dispatch({ type: 'OCPI_EXTERNAL_VIEW_SINGLE_PARTNER_TOKEN_REDUCER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// External >>> view partner >>> single partner >>> partner Api
export const fetchExternalViewSinglePartnerAction = (party_id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    // console.log("efEEWFEWFEW", OCPI_URL + VIEW_SINGLE_ROAMING_PARTNER + "?party_id=" + party_id)
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + OCPI_EXTERNAL_VIEW_SINGLE_PARTNER_API + "?party_id=" + party_id, {
            // const data = await ApiHandler(`http://192.168.1.32:7041/ocpi/secure/api/v1/get/cpo/credential/details?party_id=${party_id}`, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("OCPI_EXTERNAL_VIEW_SINGLE_PARTNER_REDUCER", data)
            dispatch({ type: 'OCPI_EXTERNAL_VIEW_SINGLE_PARTNER_REDUCER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// External >>> view partner >>> single partner >>> partner Api
export const fetchPartnerFromExternalPartnerTariffAction = (id, party_id) => {
    // console.log("id,party_id", id, party_id)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    // console.log("efEEWFEWFEW", OCPI_URL + VIEW_SINGLE_ROAMING_PARTNER + "?party_id=" + party_id)
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + OCPI_EXTERNAL_VIEW_PARTNER_TARIFF_PARTNER_DATA_API + "?party_id=" + party_id + "&id=" + id, {
            // const data = await ApiHandler(`http://192.168.1.32:7041/ocpi/secure/api/v1/get/cpo/tariff/details?party_id=${party_id}&id=${id}`, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("FETCH_PARTNER_FROM_EXTERNAL_PARTNER_TARIFF_REDUCER", data)
            dispatch({ type: 'FETCH_PARTNER_FROM_EXTERNAL_PARTNER_TARIFF_REDUCER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// External >>> view ocpi station >>> view ocpi >>> partner Api
export const fetchExternalViewSingleStationPartnerServerAction = (id, party_id) => {
    // console.log("id,party_id", id, party_id)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    // console.log("efEEWFEWFEW", OCPI_URL + VIEW_SINGLE_ROAMING_PARTNER + "?party_id=" + party_id)
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + OCPI_EXTERNAL_VIEW_STATION_PARTNER_SERVER_API + "?party_id=" + party_id + "&id=" + id, {
            // const data = await ApiHandler(`http://192.168.1.32:7041/ocpi/secure/api/v1/get/cpo/location/details?party_id=${party_id}&id=${id}`, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("FETCH_PARTNER_SERVER_DATA_FROM_EXTERNAL_STATION_REDUCER", data)
            dispatch({ type: 'FETCH_PARTNER_SERVER_DATA_FROM_EXTERNAL_STATION_REDUCER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// External >>> ocpi trnsaction report >>> view session report >>> self single session data Api
export const fetchExternalViewSingleSessionAction = (id, party_id) => {
    // console.log("id,party_id", id, party_id)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    // console.log("efEEWFEWFEW", OCPI_URL + VIEW_SINGLE_ROAMING_PARTNER + "?party_id=" + party_id)
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + OCPI_EXTERNAL_VIEW_SINGLE_SESSION_SELF_DATA_API + "?party_id=" + party_id + "&id=" + id, {
            // const data = await ApiHandler(`http://192.168.1.32:7041/ocpi/secure/api/v1/get/single/session/details?party_id=${party_id}&id=${id}`, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("FETCH_EXTERNAL_VIEW_SINGLE_SESSION_DATA_REDUCER", data)
            dispatch({ type: 'FETCH_EXTERNAL_VIEW_SINGLE_SESSION_DATA_REDUCER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// External >>> ocpi trnsaction report >>> view session report >>> partner single session data Api
export const fetchExternalViewSingleSessionPartnerAction = (id, party_id) => {
    // console.log("id,party_id", id, party_id)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    // console.log("efEEWFEWFEW", OCPI_URL + VIEW_SINGLE_ROAMING_PARTNER + "?party_id=" + party_id)
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + OCPI_EXTERNAL_VIEW_SINGLE_SESSION_PARTNER_DATA_API + "?party_id=" + party_id + "&id=" + id, {
            // const data = await ApiHandler(`http://192.168.1.29:7041/ocpi/secure/api/v1/get/cpo/session/details?party_id=${party_id}&id=${id}`, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("FETCH_EXTERNAL_VIEW_SINGLE_SESSION_PARTNER_DATA_REDUCER", data)
            dispatch({ type: 'FETCH_EXTERNAL_VIEW_SINGLE_SESSION_PARTNER_DATA_REDUCER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// External >>> ocpi trnsaction report >>> view cdr report >>> self single cdr report data Api
export const fetchExternalViewSingleSessionCdrSelfDataAction = (id, party_id) => {
    // console.log("id,party_id", id, party_id)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    // console.log("efEEWFEWFEW", OCPI_URL + VIEW_SINGLE_ROAMING_PARTNER + "?party_id=" + party_id)
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + OCPI_EXTERNAL_VIEW_SINGLE_CDR_SELF_DATA_API + "?party_id=" + party_id + "&id=" + id, {
            // const data = await ApiHandler(`http://192.168.1.32:7041/ocpi/secure/api/v1/get/single/cdrs/details?party_id=${party_id}&id=${id}`, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("FETCH_EXTERNAL_VIEW_SINGLE_CDR_SELF_DATA_REDUCER", data)
            dispatch({ type: 'FETCH_EXTERNAL_VIEW_SINGLE_CDR_SELF_DATA_REDUCER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// External >>> ocpi trnsaction report >>> view cdr report >>> partner single cdr report data Api
export const fetchExternalViewSingleSessionCdrPartnerDataAction = (id, party_id) => {
    // console.log("id,party_id", id, party_id)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    // console.log("efEEWFEWFEW", OCPI_URL + VIEW_SINGLE_ROAMING_PARTNER + "?party_id=" + party_id)
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + OCPI_EXTERNAL_VIEW_SINGLE_CDR_PARTNER_DATA_API + "?party_id=" + party_id + "&id=" + id, {
            // const data = await ApiHandler(`http://192.168.1.29:7041/ocpi/secure/api/v1/get/cpo/cdrs/details?party_id=${party_id}&id=${id}`, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("FETCH_EXTERNAL_VIEW_SINGLE_CDR_PARTNER_DATA_REDUCER", data)
            dispatch({ type: 'FETCH_EXTERNAL_VIEW_SINGLE_CDR_PARTNER_DATA_REDUCER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const updateRoamingPartner = (id, data) => {
    const { partner_name, country_code, address, city, state, country, party_id, url, token_a } = data
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + UPDATE_ROAMING_PARTNER, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "id": id,
                "partner_name": partner_name,
                'country_code': country_code,
                'address': address,
                "city": city,
                "state": state,
                'country': country,
                'url': url,
                "party_id": party_id,
                'role': role,
                "organisation": organisation,
                "tokenA": token_a

            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'UPDATE_ROAMING_PARTNER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchLocationbyPartyId = (partner_name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + FETCH_CONSUMED_LOCATION_API + "?party_id=" + partner_name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'VIEW_LOCATION_BY_PARTY_ID', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const viewSingleConsumedStation = (name, party_id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log("EWfew", OCPI_URL + FETCH_SINGLE_CONSUMED_LOCATION_API + "?location_id=" + name + "&party_id=" + party_id)
    // console.log("WrfwefF", token)
    const bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + FETCH_SINGLE_CONSUMED_LOCATION_API + "?party_id=" + party_id + "&name=" + name, {
            // const data = await ApiHandler(`http://192.168.1.32:7041/ocpi/secure/api/v1/get/cpo/location?party_id=${party_id}&name=${name}`, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_SINGLE_CONSUMED_LOCATION', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const activateRoamingPartner = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler("http://192.168.1.17:7041/ocpi/secure/api/v1/activate/roamingpartner?id=6596a1744705d79425d6f19b", {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'ACTIVATE_ROAMING_PARTNER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const deactivateRoamingPartner = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler("http://192.168.1.17:7041/ocpi/secure/api/v1/deactivate/roamingpartner?id=6596a1744705d79425d6f19b", {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'DEACTIVATE_ROAMING_PARTNER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchAllPartnerTariff = (party_id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + FETCH_PARTNER_TARIFF_API + "?party_id=" + party_id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_PARTNER_TARIFF', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const viewSingleConsumedTariff = (id, party_id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + FETCH_PARTNER_TARIFF_BY_ID_API + "?id=" + id + "&party_id=" + party_id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_PARTNER_TARIFF_BY_ID', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const refreshPartnerStationAction = (party_id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log("OCPI_URL + REFRESH_PARTNER_STATIONrty_id", OCPI_URL + REFRESH_PARTNER_STATION_API + "?party_id=" + party_id)
    const bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + REFRESH_PARTNER_STATION_API + "?party_id=" + party_id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            console.log("dwadada", data)
            dispatch({ type: 'REFRESH_PARTNER_STATION_BY_ID', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const refreshPartnerTariffAction = (party_id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + REFRESH_PARTNER_TARIFF_API + "?party_id=" + party_id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'REFRESH_PARTNER_TARIFF_BY_ID', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const generateTokenAction = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + GENERATE_TOKEN_API, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'GENERATE_TOKEN', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchOcpiStationAction = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + FETCH_OCPI_STATION_LIST, {
            // const data = await ApiHandler("http://192.168.1.20:7041/ocpi/secure/api/v1/fetch/locations", {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_OCPI_STATION_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchSingleOcpiStationAction = (name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + FETCH_SINGLE_OCPI_STATION_LIST + "?name=" + name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_SINGLE_OCPI_STATION_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchExternalTransactionListAction = (party_id, currentPage, pageSize) => {
    // console.log("party_id: ", party_id)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    // console.log(OCPI_URL + FETCH_EXTERNAL_TRANSACTION_LIST_API + "?status=" + party_id)
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + FETCH_EXTERNAL_TRANSACTION_LIST_API + "?party_id=" + party_id + "&page=" + currentPage + "&limit=" + pageSize, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("FETCH_EXTERNAL_TRANSACTION_LIST", data)
            dispatch({ type: 'FETCH_EXTERNAL_TRANSACTION_LIST', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}
export const fetchAllExternalTransactionDataAction = (party_id, currentPage, pageSize) => {
    // console.log("party_id: ", party_id)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    // console.log(OCPI_URL + FETCH_EXTERNAL_TRANSACTION_LIST_API + "?status=" + party_id)
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + FETCH_EXTERNAL_TRANSACTION_LIST_API + "?party_id=" + party_id + "&page=" + currentPage + "&limit=" + pageSize, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("FETCH_ALL_EXTERNAL_TRANSACTION_LIST", data)
            dispatch({ type: 'FETCH_ALL_EXTERNAL_TRANSACTION_LIST', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchInternalTransactionDropdownAction = () => {
    // console.log("party_id: ", party_id)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    // console.log(OCPI_URL + FETCH_EXTERNAL_TRANSACTION_LIST_API + "?status=" + party_id)
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + FETCH_INTERNAL_TRANSACTION_PARTNER_DROPDOWN_URL, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("FETCH_INTERNAL_TRANSACTION_DROPDOWN_LIST_REDUCER", data)
            dispatch({ type: 'FETCH_INTERNAL_TRANSACTION_DROPDOWN_LIST_REDUCER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchInternalTransactionListAction = (party_id, currentPage, pageSize) => {
    return async dispatch => {
        const { token } = JSON.parse(localStorage.getItem('user'));
        const bearer = 'basic ' + token;

        dispatch({ type: 'OCPI_LOADING', payload: '' });
        try {
            const response = await fetch(OCPI_URL + FETCH_INTERNAL_TRANSACTION_LIST_API + "?party_id=" + party_id + "&page=" + currentPage + "&limit=" + pageSize, {
                method: "GET",
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }
            });

            if (!response.ok) {
                // Throw an error if response status is not OK
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            // console.log("FETCH_INTERNAL_TRANSACTION_LIST", data);
            dispatch({ type: 'FETCH_INTERNAL_TRANSACTION_LIST', payload: data });

        } catch (error) {
            console.error("Error fetching internal transaction list:", error);
        }
    }
}
export const fetchAllInternalTransactionListAction = (party_id, currentPage, pageSize) => {
    // console.log("party_id", party_id);
    return async dispatch => {
        const { token } = JSON.parse(localStorage.getItem('user'));
        const bearer = 'basic ' + token;

        dispatch({ type: 'OCPI_LOADING', payload: '' });
        try {
            // console.log(OCPI_URL + FETCH_INTERNAL_TRANSACTION_LIST_API + "?party_id=" + party_id + "&page=" + currentPage + "&limit=" + pageSize)
            // console.log("http://192.168.1.27:7041/ocpi/secure/api/v1/fetch/charging/sessions" + "?party_id=" + party_id + "&page=" + currentPage + "&limit=" + pageSize)
            const response = await fetch(OCPI_URL + FETCH_INTERNAL_TRANSACTION_LIST_API + "?party_id=" + party_id + "&page=" + currentPage + "&limit=" + pageSize, {
                method: "GET",
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }
            });

            if (!response.ok) {
                // Throw an error if response status is not OK
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            // console.log("FETCH_ALL_INTERNAL_TRANSACTION_LIST", data);
            dispatch({ type: 'FETCH_ALL_INTERNAL_TRANSACTION_LIST', payload: data });

        } catch (error) {
            console.error("Error fetching internal transaction list:", error);
        }
    }
}

export const fetchInternalEmspPartnerListAction = (org) => {
    // console.log("party_id", org)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    // console.log(OCPI_URL + FETCH_EMSP_PARTNER_LIST_API + "?status=" + "All")
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + FETCH_EMSP_PARTNER_LIST_API + "?party_id=" + org, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_INTERNAL_EMSP_PARTNER_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchAllEmspPartyIdAction = () => {
    // console.log("party_id", party_id)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        const data = await ApiHandler(OCPI_URL + FETCH_ALL_EMSP_PARTY_ID_API, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_ALL_EMSP_PARTY_ID', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchOcpiLocationStatus = (status, currentPage, pageSize) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' });

        try {
            // console.log(`${OCPI_URL + FETCH_OCPI_LOCATION_STATUS_API}?status=${status}&page=${currentPage}&limit=${pageSize}`)
            const response = await fetch(`${OCPI_URL + FETCH_OCPI_LOCATION_STATUS_API}?status=${status}&page=${currentPage}&limit=${pageSize}`, {
                method: "GET",
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }
            });

            // Check if the response is OK
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            // console.log('status data', data)
            dispatch({ type: 'FETCH_OCPI_LOCATION_STATUS_API', payload: data });

        } catch (error) {
            console.error("Error fetching OCPI location status:", error);
        }
    }
}

export const fetchOcpiLocationCount = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' });

        try {
            const response = await fetch(OCPI_URL + FETCH_OCPI_LOCATION_COUNT_API, {
                method: "GET",
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }
            });

            // Check if the response is OK
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            dispatch({ type: 'FETCH_OCPI_LOCATION_COUNT_API', payload: data });

        } catch (error) {
            console.error("Error fetching OCPI location status:", error);
        }
    }
}

// Refresh location cache api

export const ocpiExternalRefreshLocationAction = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' });

        try {
            const response = await fetch(OCPI_URL + OCPI_EXTERNAL_REFRESH_LOCATION_CACHE_API, {
                method: "GET",
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }
            });

            // Check if the response is OK
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            // console.log("data from action", data);
            dispatch({ type: 'OCPI_EXTERNAL_REFRESH_LOCATION_CACHE_REDUCER', payload: data });

        } catch (error) {
            console.error("Error fetching OCPI location status:", error);
        }
    }
}