import { FETCH_SIX_MONTH_GRAPH_API, ADVERTISE_URL, CREATE_ADVERTISEMENT_API, FETCH_ADVERTISEMENT_API, FETCH_SINGLE_ADVERTISE_API, FETCH_ACTIVATE_ADVERTISMENT_API, FETCH_DEACTIVATE_ADVERTISMENT_API, UPDATE_ADVERTISEMENT_API, FETCH_ADVERTISEMENT_OVERVIEW_DATA, FETCH_ADVERTISEMENT_DROPDOWN_API, FETCH_ADVERTISE_CLICK_COUNT_API, FETCH_ALL_ADVERTISE_REPORT_API } from "../../components/config/config";
import { ApiHandler } from "./apiHandler";

export const createAdvertisementAction = (formData) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token;

  return async (dispatch) => {
    dispatch({ type: 'LOAD_ADVERTISE', payload: '' });
    const data = await ApiHandler(ADVERTISE_URL + CREATE_ADVERTISEMENT_API, {
      method: "POST",
      headers: {
        'Authorization': bearer,
      },
      body: formData,
    })
    if (data) {
      dispatch({ type: 'SAVE_ADVERTISE', payload: data });
    } else {
      console.error('Error creating advertisement:');
    }
  };
};

export const updateAdvertise = (formData) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token;

  return async dispatch => {
    dispatch({ type: 'LOAD_ADVERTISE', payload: '' })
    const data = await ApiHandler(ADVERTISE_URL + UPDATE_ADVERTISEMENT_API, {
      method: "POST",
      headers: {
        'Authorization': bearer,
      },
      body: formData,
    })
    if (data) {
      dispatch({ type: 'UPDATE_ADVERTISE_API_DATA', payload: data })
    } else {
      console.error('Error updatung advertisement:');
    }
  }
}

export const allAdvertise = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  // console.log(ADVERTISE_URL + FETCH_ADVERTISEMENT_API+ "?role=" + role + "&organisation=" + organisation)
  return async dispatch => {
    dispatch({ type: 'LOAD_ADVERTISE', payload: '' })
    const data = await ApiHandler(ADVERTISE_URL + FETCH_ADVERTISEMENT_API + "?role=" + role + "&organisation=" + organisation, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'LOAD_ADVERTISE_LIST', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchGraphApi = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  // console.log(ADVERTISE_URL + FETCH_SIX_MONTH_GRAPH_API+ "?role=" + role + "&organisation=" + organisation)
  return async dispatch => {
    dispatch({ type: 'LOAD_ADVERTISE', payload: '' })
    const data = await ApiHandler(ADVERTISE_URL + FETCH_SIX_MONTH_GRAPH_API + "?role=" + role + "&organisation=" + organisation, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_SIX_MONTH_GRAPH_API', payload: data })
    } else {
      console.log("No data found from server")
    }

  }
}

export const viewSingle = (id) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_ADVERTISE', payload: '' })
    const data = await ApiHandler(ADVERTISE_URL + FETCH_SINGLE_ADVERTISE_API + "?id=" + id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_SINGLE_ADVERTISE_DATA', payload: data })
    } else {
      console.log("No data found from server")
    }

  }
}

export const activateAdvertise = (advertiseid) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_ADVERTISE', payload: '' })
    const data = await ApiHandler(ADVERTISE_URL + FETCH_ACTIVATE_ADVERTISMENT_API + "?advertiseid=" + advertiseid, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_ACTIVATE_DATA', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const deactivateAdvertise = (advertiseid) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_ADVERTISE', payload: '' })
    const data = await ApiHandler(ADVERTISE_URL + FETCH_DEACTIVATE_ADVERTISMENT_API + "?advertiseid=" + advertiseid, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_DEACTIVATE_DATA', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

// Advertisment report action

export const getTotalOveriewDataAPI = (name, startDate, endDate) => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  // console.log(token)
  const bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'LOAD_ADVERTISE', payload: '' })
    const data = await ApiHandler(ADVERTISE_URL + FETCH_ADVERTISEMENT_OVERVIEW_DATA + "?station_name=" + name + "&start=" + startDate + "&end=" + endDate, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_OVERVIEWDATA_API', payload: data });
    } else {
      console.log("No data found from server")
    }
  }
}

export const advertisementNameDropdownApi = () => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'LOAD_ADVERTISE', payload: '' })
    const data = await ApiHandler(ADVERTISE_URL + FETCH_ADVERTISEMENT_DROPDOWN_API, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_DROPDOWN_DATA', payload: data });
    } else {
      console.log("No data found from server")
    }
  }
}

export const advertiseClickCountApi = (name, startDate, endDate) => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'LOAD_ADVERTISE', payload: '' })
    const data = await ApiHandler(ADVERTISE_URL + FETCH_ADVERTISE_CLICK_COUNT_API + "?station_name=" + name + "&start=" + startDate + "&end=" + endDate, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'FETCH_ADVERTISE_CLICK_COUNT_API', payload: data });
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchAllReport = (name, startDate, endDate) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const operator = role === "Admin" ? 'All' : organisation;
  const bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'LOAD_ADVERTISE', payload: '' })
    const data = await ApiHandler(ADVERTISE_URL + FETCH_ALL_ADVERTISE_REPORT_API + "?start=" + startDate + "&end=" + endDate + "&station_name=" + name, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    });
    if (data) {
      dispatch({ type: 'FETCH_ALL_ADVERTISE_API', payload: data });
    } else {
      console.log("No data found from server")
    }
  }
}

