import { data } from "jquery";
import {
  FETCH_VEHICLE_DATA,
  FLEET_URL,
  FETCH_LOCATION_API,
  FETCH_VEHICLE_LOCATION_API,
  FETCH_HEAVY_VEHICLE_API,
  FETCH_LIGHT_VEHICLE_API,
  SAVE_FLEET_VEHICLE_API,
  SAVE_FLEET_OWNER_API,
  FETCH_FLEET_VEHICLEBYOPERATOR_API,
  CHARGING_SERVICE_URL,
  FETCH_FLEET_LIVE_CHARGING,
  FETCH_ALL_FLEET_NAME,
  FETCH_FLEET_INSIGHTS,
  FETCH_FLEET_CHARGING,
  FETCH_FLEET_REPORT,
  FETCH_FLEET_CONSUMPTION_GRAPH,
  FETCH_FLEET_REVENUE_GRAPH,
  FETCH_FLEET_NAME,
  FETCH_VIN_NO_API,
  FETCH_FLEET_DATA,
  FETCH_VIN_DATA,
  SAVE_VEHICLE_CHARGING_PRIORITY_API,
  SAVE_FLEET_SCHEDULE,
  SAVE_VEHICLE,
  FETCH_SCHEDULE,
  FETCH_EVENT,
  FETCH_SINGLE_SCHEDULE,
  UPDATE_SINGLE_SCHEDULE,
  DELETE_SINGLE_SCHEDULE,
  DELETE_SINGLE_VEHICLE,
  UPDATE_FLEET_STATUS,
  FETCH_SINGLE_VEHICLE,
  UPDATE_SINGLE_VEHICLE,
  FLEET_LIVE_CHARGING,
} from "../../components/config/config";
import { ApiHandler } from "./apiHandler";

export const allFleetLocationAction = () => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(FLEET_URL + FETCH_LOCATION_API, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "LOAD_FLEET_LOCATION_LIST", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const getVehicleLocationAction = (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(FLEET_URL + FETCH_VEHICLE_LOCATION_API + "?lastid=" + id, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "LOAD_VEHICLE_LOCATION_DATA", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const getHeavyVehicleAction = (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(FLEET_URL + FETCH_HEAVY_VEHICLE_API, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "LOAD_HEAVY_VEHICLE_DATA", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const getLightVehicleAction = (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(FLEET_URL + FETCH_LIGHT_VEHICLE_API, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "LOAD_LIGHT_VEHICLE_DATA", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const saveVehicleChargingPriorityApi = (data) => {
  const { vin, charging_priority } = data;
  const { token, organisation, role } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(FLEET_URL + SAVE_VEHICLE_CHARGING_PRIORITY_API, {
      method: "POST",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        vin: vin,
        charging_priority: Number(charging_priority),
        organisation: organisation,
        role: role,
      }),
    });
    if (data) {
      dispatch({ type: "SAVE_VEHICLE_CHARGING_PRIORITY_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const createFleetOwnerAction = (data) => {
  const { ownername, organisations } = data;
  // console.log(ownername);
  const { token, role } = JSON.parse(localStorage.getItem("user"));
  // console.log(status);
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(FLEET_URL + SAVE_FLEET_OWNER_API, {
      method: "POST",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ownername: ownername,
        role: role,
        organisation: organisations,
        // "status": status,
      }),
    });
    if (data) {
      // console.log(data)
      dispatch({ type: "SAVE_FLEET_OWNER", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const fetchFleetVehiclebyOperator = (fleet) => {
  const { token, organisation, role } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(
      FLEET_URL +
        FETCH_FLEET_VEHICLEBYOPERATOR_API +
        "?role=" +
        role +
        "&organisation=" +
        organisation +
        "&fleet=" +
        fleet,
      {
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (data) {
      dispatch({ type: "FETCH_VEHICLE_BY_OPERATOR", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const fetchFleetLiveCharging = () => {
  const { token, organisation, role } = JSON.parse(localStorage.getItem("user"));
  const operator = role === "Admin" ? "All" : organisation;
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(CHARGING_SERVICE_URL + FETCH_FLEET_LIVE_CHARGING + "?operator=" + operator, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "FETCH_FLEET_LIVE_CHARGING", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const getAllFleetName = () => {
  const { token, organisation, role } = JSON.parse(localStorage.getItem("user"));
  const operator = role === "Admin" ? "All" : organisation;
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(FLEET_URL + FETCH_ALL_FLEET_NAME + "?role=" + role + "&organisation=" + operator, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "FETCH_ALL_FLEET_NAME", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const getFleetInsights = (fleet) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(
      FLEET_URL + FETCH_FLEET_INSIGHTS + "?fleet=" + fleet + "&role=" + role + "&organisation=" + organisation,
      {
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (data) {
      dispatch({ type: "FETCH_FLEET_INSIGHTS", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const fleetLiveCharging = (fleet) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem("user"));
  const operator = role === "Admin" ? "All" : organisation;
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(FLEET_URL + FETCH_FLEET_CHARGING + "?operator=" + operator, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "FLEET_LIVE_CHARGING", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const getFleetReport = (fleet, start, end) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem("user"));
  const operator = role === "Admin" ? "All" : organisation;
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(
      FLEET_URL + FETCH_FLEET_REPORT + "?operator=" + operator + "&start=" + start + "&end=" + end + "&fleet=" + fleet,
      {
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (data) {
      dispatch({ type: "FETCH_FLEET_REPORT", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const getMonthlyConsumptionReport = (fleet, start, end) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem("user"));
  const operator = role === "Admin" ? "All" : organisation;
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(
      FLEET_URL +
        FETCH_FLEET_CONSUMPTION_GRAPH +
        "?operator=" +
        operator +
        "&fleet=" +
        fleet +
        "&start=" +
        start +
        "&end=" +
        end,
      {
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (data) {
      dispatch({ type: "FETCH_FLEET_CONSUMPTION_GRAPH", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const getMonthlyRevenueReport = (fleet, start, end) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem("user"));
  const operator = role === "Admin" ? "All" : organisation;
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(
      FLEET_URL +
        FETCH_FLEET_REVENUE_GRAPH +
        "?operator=" +
        operator +
        "&start=" +
        start +
        "&end=" +
        end +
        "&fleet=" +
        fleet,
      {
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (data) {
      dispatch({ type: "FETCH_FLEET_REVENUE_GRAPH", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const fetchVehicleData = () => {
  const { token, organisation, role } = JSON.parse(localStorage.getItem("user"));
  const operator = role === "Admin" ? "All" : organisation;
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(FLEET_URL + FETCH_VEHICLE_DATA + "?organisation=" + operator, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "FETCH_FLEET_VEHICLE_DATA", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

// New Fleet API integration

export const fetchFleetName = () => {
  const { token, organisation, role } = JSON.parse(localStorage.getItem("user"));
  const operator = role === "Admin" ? "All" : organisation;
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(FLEET_URL + FETCH_FLEET_NAME + "?organisation=" + operator, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "FETCH_FLEET_NAME_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};
export const fetchVinNo = (fleet_name) => {
  const { token, organisation, role } = JSON.parse(localStorage.getItem("user"));
  const operator = role === "Admin" ? "All" : organisation;
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(FLEET_URL + FETCH_VIN_NO_API + "?fleet=" + fleet_name, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "FETCH_VIN_NUMBER_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};
export const fetchVinDataApi = (vin) => {
  const { token, organisation, role } = JSON.parse(localStorage.getItem("user"));
  const operator = role === "Admin" ? "All" : organisation;
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    // console.log(FLEET_URL + FETCH_VEHICLE_DATA)
    const data = await ApiHandler(FLEET_URL + FETCH_VIN_DATA + "?vin=" + vin, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "FETCH_VIN_DATA_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const CreateSchedule = (payload) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem("user"));
  // console.log(status);
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(FLEET_URL + SAVE_FLEET_SCHEDULE, {
      method: "POST",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        role,
        organisation,
        status: "Active",
        ...payload,
      }),
    });
    if (data) {
      dispatch({ type: "SAVE_FLEET_SCHEDULE_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const SaveVehicle = (payload) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem("user"));
  // console.log(status);
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(FLEET_URL + SAVE_VEHICLE, {
      method: "POST",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        role,
        organisation,
        status: "Active",
        ...payload,
      }),
    });
    if (data) {
      dispatch({ type: "SAVE_VEHICLE_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};
export const fetchSchedule = (fleet = "All") => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  // const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    // console.log(FLEET_URL + FETCH_VEHICLE_DATA)
    const data = await ApiHandler(FLEET_URL + FETCH_SCHEDULE + "?fleet=" + fleet, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "FETCH_SCHEDULE_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};
export const fetchEvent = (fleet = "All") => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    // console.log(FLEET_URL + FETCH_VEHICLE_DATA)
    const data = await ApiHandler(FLEET_URL + FETCH_EVENT + "?fleet=" + fleet, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "FETCH_EVENT_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};
export const fetchSingleSchedule = (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    // console.log(FLEET_URL + FETCH_VEHICLE_DATA)
    const data = await ApiHandler(FLEET_URL + FETCH_SINGLE_SCHEDULE + "?id=" + id, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "FETCH_SINGLE_SCHEDULE_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};
export const updateSchedule = (payload) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem("user"));
  // console.log(status);
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(FLEET_URL + UPDATE_SINGLE_SCHEDULE, {
      method: "POST",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        role,
        organisation,
        status: "Active",
        ...payload,
      }),
    });
    if (data) {
      dispatch({ type: "UPDATE_SINGLE_SCHEDULE_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const deleteSingleSchedule = (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    // console.log(FLEET_URL + FETCH_VEHICLE_DATA)
    const data = await ApiHandler(FLEET_URL + DELETE_SINGLE_SCHEDULE + "?id=" + id, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "DELETE_SINGLE_SCHEDULE_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const UpdateFleetStatus = (id, status) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(FLEET_URL + UPDATE_FLEET_STATUS, {
      method: "POST",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        id,
        role,
        organisation,
        status,
      }),
    });
    if (data) {
      dispatch({ type: "UPDATE_FLEET_STATUS_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};
export const FetchSingleVehicle = (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(FLEET_URL + FETCH_SINGLE_VEHICLE + "?id=" + id, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "FETCH_SINGLE_VEHICLE_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const deleteSingleVehicle = (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(FLEET_URL + DELETE_SINGLE_VEHICLE + "?id=" + id, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "DELETE_SINGLE_VEHICLE_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const UpdateSingleVehicle = (payload) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    // console.log(FLEET_URL + FETCH_VEHICLE_DATA)
    const data = await ApiHandler(FLEET_URL + UPDATE_SINGLE_VEHICLE, {
      method: "POST",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        role,
        organisation,
        ...payload,
      }),
    });
    if (data) {
      dispatch({ type: "UPDATE_SINGLE_VEHICLE_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const LiveCharging = (fleet) => {
  const { token, organisation } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  return async (dispatch) => {
    dispatch({ type: "LOAD_FLEET", payload: "" });
    const data = await ApiHandler(FLEET_URL + FLEET_LIVE_CHARGING + "?operator=" + "All", {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "FLEET_LIVE_CHARGING_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};
