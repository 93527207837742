import React, { useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { BsThreeDotsVertical } from "react-icons/bs";
import { fetchStationSessionAction } from "../../../../store/actions/insightAction";
import ApexCharts from "react-apexcharts";
import { useTranslation } from "react-i18next";

function StationGraph(props) {
  const currency = process.env.REACT_APP_CURRENCY;
  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      props.getStationSession();
    }, 1500);
  }, []);

  // console.log(props.station_session);
  const data = props.station_session && props.station_session.data

  // console.log(data);
  const energy = data && data.map((item) => item.energy)
  // console.log("energy",energy);
  const total = data && data.map((item) => item.total);
  // console.log("total",total);
  // const stationID = props.station_session && props.station_session.data.map((m) => m.stationid);
  // console.log("stationID",stationID);

  const station = {
    options: {
      chart: {
        type: "bar",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          barWidth: "80%",
          // columnWidth: "90%",
          distributed: false,
          borderRadius: 5,
        },
      },
      xaxis: {
        categories:
          props.station_session &&
          props.station_session.data && props.station_session.data.map((m) => m.stationid),
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
      yaxis: [
        {
          title: {
            text: "Total Life-time Energy",
          },
          labels: {
            formatter: function (value) {
              return value.toFixed(2);
            },
          },
        },
        {
          opposite: true,
          title: {
            text: "Total Life-time Revenue",
          },
          labels: {
            formatter: function (value) {
              return `${currency} ${value.toFixed(2)}`;
            },
          },
        },
      ],
      legend: {
        position: "top",
        offsetY: 0,
      },
      fill: {
        type: "gradient",
        gradient: {
          type: "vertical",
          shadeIntensity: 0.5,
          opacityFrom: 1,
          opacityTo: 0.8,
          stops: [0, 100],
        },
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Total Life-time Energy",
        data:
          props.station_session && props.station_session.data && props.station_session.data.map((m) => m.energy),
      },
      {
        name: "Total Life-time Revenue",
        data:
          props.station_session && props.station_session.data && props.station_session.data.map((m) => m.total),
      },
    ],
  };

  return (
    <div>
      <Card className="statuscard">
        <Card.Header>
          <Row>
            <Col xs={12} md={4}>
              <div className="text-left">{t("Leading Stations")}</div>
            </Col>
            <Col>
              <Col className="text-right">
                <div className="btn-group dropleft">
                  <button
                    className="btn btn-md"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {
                      <i className="" title="Comparision Graph">
                        <BsThreeDotsVertical />
                      </i>
                    }
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a className="dropdown-item" href="./stationcomparision">
                      {t("Station Comparision Graph")}
                    </a>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Card.Header>
        <div>
          {props.isStationGraphLoading ? <h3 style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "387px",
          }}>Loading data...</h3> :
            energy && energy.length > 0 || total && total.length > 0 ? (

              <ApexCharts
                options={station.options}
                series={station.series}
                type="bar"
                height={387}
              />) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "387px",
                }}
              >
                <h3>{t("No data found")} </h3>
              </div>
            )}
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    station_session: state.insight.station_total_session,
    isStationGraphLoading: state.insight.isStationGraphLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStationSession: () => dispatch(fetchStationSessionAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StationGraph);
