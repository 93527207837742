
import React, { useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Card, Row, Col, Container } from "react-bootstrap";
import { singleTimeOfUseTariffAction } from "../../../../store/actions/tariffAction";
import PureBreadcrumbs from "../../breadcrums";
import { useTranslation } from "react-i18next";

function ViewSingleTimeOfUse(props) {
    const { t } = useTranslation();
    const { id } = useParams()
    const priceSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        description: Yup.string().required('Description is required'),
        currency: Yup.string().required('Currency is required'),
        status: Yup.string().required('Status is required'),
        isPeak: Yup.string().required('Select peak hour is required'),
        idleTime: Yup.string(),
        idlePrice: Yup.number(),
        gracePeriod: Yup.number(),
        components: Yup.array().of(
            Yup.object().shape({
                unit: Yup.string().required('Unit  is required'),
                amount: Yup.number().required('Amount is required').typeError('Amount can only be a number'),
                tax: Yup.number().required('Tax is required').typeError('Tax can only be a number'),
            })),
    });
    const { control, getValues, setValue } = useForm({ resolver: yupResolver(priceSchema), mode: "all" });
    const { fields: fieldsList1 } = useFieldArray({ control, name: "components" });

    useEffect(() => {
        props.getSingleTariffData(id);
    }, []);

    const data = props.fetchSingleTariffDetails && props.fetchSingleTariffDetails
    // console.log(data);

    useEffect(() => {
        const { name, description, currency, status, components, idleTime, idlePrice, gracePeriod, ispeak } = data
        // console.log(ispeak)
        if (data) {
            setTimeout(() => {

                setValue("name", name, { shouldValidate: true });
                setValue("description", description, { shouldValidate: true });
                setValue("currency", currency, { shouldValidate: true });
                setValue("status", status, { shouldValidate: true });
                setValue("isPeak", ispeak, { shouldValidate: true });
                setValue("components", components, { shouldValidate: true });
                setValue("idleTime", idleTime);
                setValue("idlePrice", idlePrice);
                setValue("gracePeriod", gracePeriod);

            }, 1000);
        }
    }, [data], setValue)
    // console.log(getValues("isPeak"))

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <Row className="mt-1">
                <Col>
                    <Card className="tariffcard">
                        <Card.Body>
                            <h6 className='text-start p-0'><b>{t("Details")}</b></h6>

                            <fieldset key={1} disabled>
                                <div className="row g-2">
                                    <div className="col-md">
                                        <div className="form-floating">
                                            <label>{t("Tariff Name")}</label>
                                            <input type="text" className={`form-control gray-border`} readOnly name="name" id="floatingInputGridName" defaultValue={getValues('name') || ''} />
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="form-floating">
                                            <label>{t("Selected Currency")}</label>
                                            <input type="text" className={`form-control gray-border`} readOnly name="currency" id="floatingInputGridcurrency" defaultValue={getValues("currency") || ''} />
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="form-floating ">
                                            <label>{t("Tariff Status")}</label>
                                            <input type="text" className={`form-control gray-border`} readOnly name="status" id="floatingInputGridstatus" defaultValue={getValues("status") || ''} />

                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="form-floating">
                                            <label>{t("Tariff for")}<span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border`} readOnly name="isPeak" id="floatingInputGridisPeak" defaultValue={getValues('isPeak') || ''} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row g-2 mt-2'>
                                    <div className='col-md'>
                                        <div className="form-floating">
                                            <label htmlFor="floatingTextarea2">{t("Description")}</label>
                                            <textarea className={`form-control  gray-border`} readOnly placeholder="Description" name="description" id="floatingTextarea2" style={{ "height": "100px" }} defaultValue={getValues("description") || ''}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                                <div className='row g-2 mt-0'>
                                    <h6 className='text-start p-0'><b>{t("Price Components")}</b></h6>
                                    <div className='col-12 mt-5'>
                                        {fieldsList1.map((item, i) => (
                                            <div key={i} className="row g-0 p-0">
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("Select Charging Unit")}</label>
                                                        <input type="text" className={`form-control gray-border`} readOnly name={`components[${i}].unit`} id="floatingSelectGridU" defaultValue={getValues(`components.${i}.unit`) || ''} />
                                                    </div>
                                                    <br />
                                                </div>
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("Price Per/Unit")}</label>
                                                        <input type="number" className={`form-control gray-border`} readOnly name={`components[${i}].amount`} id="floatingInputAmt" defaultValue={getValues(`components.${i}.amount`) || 0} />
                                                    </div>
                                                </div>
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("Tax")} (%)</label>
                                                        <input type="number" className={`form-control gray-border`} readOnly name={`components[${i}].tax`} id="floatingInputTax" defaultValue={getValues(`components.${i}.tax`) || 0} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                                <hr></hr>
                                <div className='row g-2 mt-0'>
                                    <h6 className='text-start p-0'><b>{t("Idle/Parking Fee")}</b></h6>

                                    <div className='col-auto mt-5'>
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Idle Time")}</label>
                                                    <input className={`form-control`} placeholder="Idle Time" readOnly name="idleTime" id="floatingTextarea1" defaultValue={getValues("idleTime") || ''}></input>
                                                </div>
                                            </div>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Idle Price")}</label>
                                                    <input className={`form-control`} placeholder="Idle Price" readOnly name="idlePrice" id="floatingInputPrice" defaultValue={getValues("idlePrice") || ''}></input>
                                                </div>
                                            </div>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Gress Period")}</label>
                                                    <input className={`form-control`} placeholder="Grace Period" readOnly name="gracePeriod" id="floatingSelectGress" defaultValue={getValues("gracePeriod") || ''}></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    )

}


const mapStateToProps = function (state) {
    return {
        fetchSingleTariffDetails: state.tariff.timeslotSingleFetch,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getSingleTariffData: (id) => dispatch(singleTimeOfUseTariffAction(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewSingleTimeOfUse));