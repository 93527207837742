
import React, { useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Card, Row, Col, Container } from "react-bootstrap";
import { singleTimeSlotTariff } from "../../../../store/actions/tariffAction";
import PureBreadcrumbs from "../../breadcrums";
import { useTranslation } from "react-i18next";

function ViewSingleTimeSlot(props) {
    const { t } = useTranslation();
    const { id } = useParams()
    // console.log(id)
    const priceSchema = Yup.object().shape({
        station_name: Yup.string().required("Station Name is required"),
        plan_name: Yup.string().required('Plan Name is required'),
        timeslots: Yup.array().of(
            Yup.object().shape({
                day: Yup.string().required('Day is required'),
                start_time: Yup.string().required('Start From is required'),
                end_time: Yup.string().required('End To is required'),
                tariff_name: Yup.string().required('Tariff name is required'),
            })),
    });
    const { control, getValues, setValue, formState: { errors } } = useForm({ resolver: yupResolver(priceSchema), mode: "all" });
    const { fields: fieldsList1 } = useFieldArray({ control, name: "timeslots" });

    useEffect(() => {
        props.getSingleTariffData(id);
    }, []);

    const data = props.fetchSingleTariffDetails && props.fetchSingleTariffDetails.data
    // console.log(data);
    useEffect(() => {
        const { timeslot, station_name, plan_name } = data;
        if (data) {
            setTimeout(() => {
                setValue("timeslots", timeslot, { shouldValidate: true });
                setValue("station_name", station_name, { shouldValidate: true });
                setValue("plan_name", plan_name, { shouldValidate: true });
            }, 1000);
        }
    }, [data], setValue)

    // console.log(getValues("station_name"));
    return (
        <Container fluid>
            <PureBreadcrumbs />
            <Row className="mt-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <Row className="d-flex justify-content-center">
                                <h6>{t("View Tariff Time Slot")}</h6>
                            </Row>
                            <Row className="mb-2" >
                                <div className="col-lg-6">
                                    <div className="form-floating">
                                        <label>{t("Select Station Name")}</label>
                                        <input type="text" readOnly className={`form-control gray-border`} name={`station_name`} id="floatingInputstation_name" value={getValues(`station_name`) || ''} disabled />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-floating">
                                        <label>{t("Plan Name")}</label>
                                        <input type="text" readOnly className={`form-control gray-border`} name={`plan_name`} id="floatingInputplan_name" value={getValues(`plan_name`) || ''} disabled />
                                    </div>
                                </div>
                            </Row>
                            <fieldset disabled>
                                {fieldsList1.map((item, i) => (
                                    <div key={i}>
                                        <Card className='tariff-card mb-2' key={i}>
                                            {/* {i === 0 ? <h6 className='text-start p-3'><b>View Time Slots</b></h6> : ''} */}
                                            <Card.Body>
                                                <div className="row g-2 mt-3 ">
                                                    <div className="col-md">
                                                        <div className="form-floating">
                                                            <label>{t("Selected Day")} </label>
                                                            <select className={`form-select form-control`} readOnly name={`timeslots[${i}].day`} id="floatingSelectGridDay" value={getValues(`timeslots.${i}.day`) || ''}>
                                                                <option value={getValues(`timeslots.${i}.day`)}>{getValues(`timeslots.${i}.day`)}</option>
                                                            </select>
                                                        </div>

                                                    </div>

                                                    <div className="col-md">
                                                        <div className="form-floating">
                                                            <label>{t("From")} </label>
                                                            <select className={`form-select form-control`} readOnly name={`timeslots[${i}].start_time`} id="floatingSelectGridstart_time" value={getValues(`timeslots.${i}.start_time`) || ''}>
                                                                <option value={getValues(`timeslots.${i}.start_time`)}>{getValues(`timeslots.${i}.start_time`)}</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <div className="col-md">
                                                        <div className="form-floating">
                                                            <label>{t("To")} </label>
                                                            <select className={`form-select form-control`} readOnly name={`timeslots[${i}].end_time`} id="floatingSelectGridend_time" value={getValues(`timeslots.${i}.end_time`) || ''}>
                                                                <option value={getValues(`timeslots.${i}.end_time`)}>{getValues(`timeslots.${i}.end_time`)}</option>
                                                            </select>
                                                        </div>

                                                    </div>

                                                    <div className="col-md">
                                                        <div className="form-floating">
                                                            <label>{t("Selected Tariff")}</label>
                                                            <select className={`form-select form-control`} readOnly name={`timeslots[${i}].tariff_name`} id="floatingSelectGridtariff_name" value={getValues(`timeslots.${i}.tariff_name`) || ''}>
                                                                <option value={getValues(`timeslots.${i}.tariff_name`)}>{getValues(`timeslots.${i}.tariff_name`)}</option>
                                                            </select>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.timeslots?.[i]?.tariff_name?.message}</div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ))}
                            </fieldset>
                        </Card.Body>

                    </Card>
                </Col>
            </Row>
        </Container>

    )

}


const mapStateToProps = function (state) {
    return {
        fetchSingleTariffDetails: state.tariff.fetchSingleTimeSlot,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getSingleTariffData: (id) => dispatch(singleTimeSlotTariff(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewSingleTimeSlot));