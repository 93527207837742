import React, { useEffect } from 'react'
import { Card, Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useState } from "react";
import { saveOrganisationPromotionCode } from "../../../../store/actions/organisationAction";
import PureBreadcrumbs from "../../breadcrums";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../organisation.css"
import { useTranslation } from 'react-i18next';
import { Bounce, ToastContainer, toast } from 'react-toastify';


const CreatePromotion = (props) => {
    const { t } = useTranslation();

    const promotionSchema = Yup.object().shape({
        promotion_code: Yup.string().required('Promotion Code is required'),
        discount: Yup.number()
            .required('Discount is required')
            .typeError('Discount can only be a number')
            .min(1, 'Discount cannot be 0 or negative')
            .max(100, 'Discount cannot exceed more then 100'),
        promotion_name: Yup.string().required('Promotion Name is required'),
    });
    const [startdate, setstartdate] = useState(new Date(Date.now() + (3600 * 1000 * 1)));
    const [enddate, setenddate] = useState(new Date(Date.now() + (3600 * 1000 * 720)));
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(promotionSchema) });
    const [isToastVisible, setIsToastVisible] = useState(false);



    const onSubmit = async data => {
        // console.log(data)
        props.savepromotion(data, startdate, enddate)
    };

    useEffect(() => {
        if (props.save_promotion.statuscode === 201) {
            setIsToastVisible(true);
            toast.success('New Promotion has been created', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.save_promotion.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.save_promotion.error}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.save_promotion]);


    // const HandleKeyPress = (e) => {
    //     const value = e.target.value;
    //     // console.log("value: ", value);
    //     // Prevent entering '0' as the first character
    //     if (e.key === '0' && value === '') {
    //         e.preventDefault();
    //     }
    //     // Prevent entering non-numeric characters and negative sign
    //     if (e.key === '-' || e.key === 'e') {
    //         e.preventDefault();
    //     }
    // };

    return (
        <Container fluid className="pb-4">
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={2}>
                </Col>
                <Col xs={12} md={8}>
                    <Card className="tariffcard">
                        <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}><b>{t("Add New Promotion")}</b></Card.Text>
                        <Card.Body>
                            <form autoComplete='off' onSubmit={e => e.preventDefault()}>
                                <Row>
                                    <Col lg={6}>
                                        <div className="form-floating">
                                            <label>{t("Enter the promotion code")}  <span className='mandatory-field'>*</span></label>
                                            <input type="text" autoFocus className={`form-control gray-border ${errors.promotion_code ? 'is-invalid' : ''}`} name="promotion_code" id="floatingInputGridpromotion_code" placeholder={t("Enter the promotion code")}  {...register("promotion_code")} />
                                            <div className="invalid-feedback">{errors.promotion_code?.message}</div>
                                        </div>
                                    </Col>

                                    <Col lg={6}>
                                        <div className="form-floating">
                                            <label>{t("Enter specific promotion name")} <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.promotion_name ? 'is-invalid' : ''}`} name="promotion_name" id="floatingInputGridnumber" placeholder={t("Enter specific promotion name")} {...register("promotion_name")} />
                                            <div className="invalid-feedback">{errors.promotion_name?.message}</div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col className="text-center">
                                        {t("Select Promotion Start Date")} :
                                    </Col>
                                    <Col>
                                        <DatePicker
                                            className='gray-border'
                                            selected={startdate}
                                            name="start"
                                            onChange={(date) => setstartdate(date)}
                                            selectsStart
                                            startdate={startdate}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                        {startdate === null && <div className='invalid-feedback fa-1x'>{t("Start Date is required")}</div>}

                                    </Col>
                                </Row><br />

                                <Row>
                                    <Col className="text-center">
                                        {t("Select Promotion End Date")} :
                                    </Col>
                                    <Col>
                                        <DatePicker
                                            className='gray-border'
                                            selected={enddate}
                                            onChange={(date) => setenddate(date)}
                                            selectsEnd
                                            startdate={startdate}
                                            enddate={enddate}
                                            minDate={startdate}
                                            showDisabledMonthNavigation
                                            dateFormat="dd/MM/yyyy"
                                        />
                                        {enddate === null && <div className='invalid-feedback fa-1x'>{t("End Date is required")}</div>}
                                    </Col>
                                </Row><br />

                                <Row>
                                    <Col >
                                        <div className="form-floating">
                                            <label>{t("Enter maximum discount to be applied")} <span className='mandatory-field'>*</span></label>
                                            <input type="number"
                                                max="100"
                                                className={`form-control gray-border ${errors.discount ? 'is-invalid' : ''}`}
                                                name="discount" id="floatingInputGriddiscount"
                                                placeholder={t("Enter maximum discount to be applied")}
                                                {...register("discount")}
                                            // onKeyPress={HandleKeyPress}

                                            />
                                            <div className="invalid-feedback">{errors.discount?.message}</div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mt-3 d-flex justify-content-center">
                                    <Col lg={5} md={3} sm={12} xs={12} className="mt-2">
                                        <button className="lgn-btn btn" type="button" onClick={() => {
                                            reset({
                                                promotion_code: '', discount: '', promotion_name: '',
                                                startDate: setstartdate(new Date(Date.now() + (3600 * 1000 * 1))),
                                                endDate: setenddate(new Date(Date.now() + (3600 * 1000 * 720))),
                                            });
                                        }}> {t("Reset")} </button>
                                    </Col>
                                    <Col lg={7} md={9} sm={12} xs={12} className='d-md-flex justify-content-end mt-2'>
                                        {props.loading ?
                                            <button className="btn lgn-btn" type="button" disabled>
                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                &nbsp;
                                                {t("Saving...")}
                                            </button>
                                            :
                                            <button className="lgn-btn btn" type="submit" disabled={startdate === null || enddate === null} onClick={handleSubmit(onSubmit)}>{t("CREATE NEW PROMOTION")}</button>
                                        }
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                        <br /><br />
                    </Card>
                </Col>
                <Col md={2}>
                </Col>
            </Row>
        </Container>
    )
}



const mapStateToProps = (state) => {
    return {
        loading: state.operator.isLoading,
        save_promotion: state.operator.savepromotion,
    }
}
const mapDispatchToProps = dispatch => {
    return {

        savepromotion: (data, startdate, enddate) => dispatch(saveOrganisationPromotionCode(data, startdate, enddate))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreatePromotion)
