import React, { useEffect, useState } from "react";
import { Row, Col, Card, Container, Spinner } from "react-bootstrap";
import "react-dates/initialize";
import PureBreadcrumbs from "../../../breadcrums";
import { useMemo } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Table from "../../../reacttable/table";
import { fetchAllInternalTransactionListAction, fetchInternalTransactionDropdownAction, fetchInternalTransactionListAction } from "../../../../../store/actions/ocpiAction";
import moment from "moment";
import { CSVLink } from "react-csv";
import CustomOcpiPaginationTable from "../../customOcpiPaginationTable";
import InternalTransactionReportPdf from "./internalTransactionReportPdf";

function InternalTransactionReport(props) {
    const { t } = useTranslation();
    const [org, setOrg] = useState("All");
    const currency = process.env.REACT_APP_CURRENCY;
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const document_count = props.list && props.list.total_session;
    const defaultPageSize = 10;
    const pageCount = Math.ceil(document_count / defaultPageSize)


    useEffect(() => {
        props.getInternalEmspDropdownApi();
        props.getTransactionList(org, currentPage, pageSize);
        props.getAllTransactionList(org, 1, 10000000);
    }, [org, currentPage, pageSize])

    const handleChange = (e) => {
        const data = e.target.value;
        setOrg(data);
    };

    const data = props.list && props.list.data
    const allData = props.allList && props.allList.data
    const processedData = allData && allData.map(item => ({
        ...item,
        date: item.date ? moment(item.date).format('DD-MM-YYYY') : 'NA',
        start_date_time: item.start_date_time ? moment(item.start_date_time).format('HH:mm:ss') : 'NA',
        end_date_time: item.end_date_time ? moment(item.end_date_time).format('HH:mm:ss') : 'NA'
    }));
    // const partnerList = props.list && props.list.data
    // console.log("data", data)

    const columns = useMemo(
        () => [
            { Header: "ID", accessor: "id" },
            // { Header: "User Name", accessor: "userid" },
            { Header: "EVSE UID", accessor: "evse_uid" },
            { Header: "Location ID", accessor: "location_id", id: "location_idd" },
            {
                Header: "Start Date",
                accessor: "date",
                Cell: (item) => {
                    const value = item.cell;
                    // console.log(item)
                    return (
                        <>
                            <p>
                                {value ? value : 'NA'}
                            </p>
                        </>
                    )
                }
            },
            {
                Header: "Start Time",
                accessor: "start_date_time",
                Cell: (item) => {
                    const value = item.cell;
                    // console.log(item)
                    return (
                        <>
                            <p>
                                {value ? moment(value).format('HH:mm:ss') : 'NA'}
                            </p>
                        </>
                    )

                }
            },
            {
                Header: "End Time",
                accessor: "end_date_time",
                Cell: (item) => {
                    const value = item.cell;
                    return (
                        <>
                            <p>
                                {value ? moment(value).format('HH:mm:ss') : 'NA'}
                            </p>
                        </>
                    )

                }
            },
            { Header: "Connector ID", accessor: "connector_id" },
            { Header: "kWh", accessor: "kwh" },
            {
                Header: "Cost Excluding Vat",
                accessor: "total_cost.excl_vat",
                Cell: (item) => {
                    const value = item.item?.total_cost?.excl_vat;
                    // console.log(value)
                    return (
                        <>
                            <p>
                                {value}
                            </p>
                        </>
                    )
                }
            },
            {
                Header: "Cost Including Vat",
                accessor: "total_cost.incl_vat",
                Cell: (item) => {
                    const value = item.item?.total_cost?.incl_vat;
                    // console.log(value)
                    return (
                        <>
                            <p>
                                {value}
                            </p>
                        </>
                    )
                }
            },
            { Header: "Status", accessor: "status" },
        ],
        []
    );

    const headers = [
        { label: "ID", key: "id" },
        { label: "EVSE ID", key: "evse_uid" },
        { label: "Location", key: "location_id" },
        { label: "Date", key: "date" },
        { label: "Start Time", key: "start_date_time" },
        { label: "End Time", key: "end_date_time" },
        { label: "Connector ID", key: "connector_id" },
        { label: "kWh", key: "kwh" },
        { label: "Cost Excluding Vat", key: "total_cost.excl_vat" },
        { label: "Cost Including Vat", key: "total_cost.incl_vat" },
        { label: "Status", key: "status" },

    ];

    const initialVisibleColumns =
        [
            'id', 'firstname', 'lastname', 'evse_uid', 'location_id', 'date', 'start_date_time',
            'end_date_time', 'userid', 'connector_id', 'kwh', 'total_cost.excl_vat', 'total_cost.incl_vat', 'status'
        ];

    return (
        <Container fluid>
            <PureBreadcrumbs />

            {/* Ev Report Cards Start here */}
            <Row className="mt-2">
                <Col className="mt-2" xs={12} md={3}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>Total Session</Card.Text>
                            <Card.Title>
                                <b>
                                    {props.list.total_session ? props.list.total_session : 0}
                                    {/* 0 */}
                                </b>
                            </Card.Title>
                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-60px",
                                    marginRight: "-8px",
                                }}
                            >
                                <div style={{ width: "15%" }}>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL + "/images/reports/sessions.svg"
                                        }
                                        style={{ "width": "60px" }}
                                        alt=""
                                    />
                                </div>
                            </span>
                        </div>
                    </Card>
                    <br />
                </Col>
                <Col className="mt-2" xs={12} md={3}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>Total Energy</Card.Text>
                            <Card.Title>

                                <b>
                                    {props.list.total_energy ? props.list.total_energy : 0}
                                </b>
                                {/* 0 */}
                            </Card.Title>
                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-60px",
                                    marginRight: "-8px",
                                }}
                            >
                                <div style={{ width: "10%" }}>
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/reports/energy.svg"}
                                        alt=""
                                        style={{ "width": "40px" }}
                                    />
                                </div>
                            </span>
                        </div>
                    </Card>
                    <br />
                </Col>
                <Col className="mt-2" xs={12} md={3}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>Total Revenue</Card.Text>
                            <Card.Title>
                                <b>
                                    {props.list.total_revenue ? props.list.total_revenue : 0}
                                </b>
                            </Card.Title>
                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-65px",
                                    marginRight: "-8px",
                                }}
                            >
                                {<div style={{ fontSize: "3.5rem", paddingRight: "11px", color: "#2ea8cc80", marginTop: "-7px" }}>
                                    {currency}
                                </div>}
                            </span>
                        </div>
                    </Card>
                </Col>
                <Col className="mt-2" xs={12} md={3}>
                    <Card className="customercard ">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>
                                Total Sessions Completed
                            </Card.Text>
                            <Card.Title>
                                <b>
                                    {props.list.total_completed ? props.list.total_completed : 0}
                                </b>
                            </Card.Title>
                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-50px",
                                    marginRight: "-8px",
                                }}
                            >
                                <div style={{ width: "10%" }}>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/reports/sessionsstats.svg"
                                        }
                                        alt=""
                                        style={{ "width": "70px" }}
                                    />
                                </div>
                            </span>
                        </div>
                    </Card>
                    <br />
                </Col>
            </Row>
            {/* Ev Report Card Ends here */}

            <Row className="mt-2">
                <Col>
                    <Card className="customercard">
                        <Card.Header>
                            <Row className="align-items-center my-3">
                                <Col md={2} sm={12} className="font-weight-bold text-left">
                                    Transaction Report
                                </Col>
                                <Col md={8} sm={12}>
                                    <div className="row justify-content-end">
                                        <div style={{ paddingRight: "10px" }}>
                                            <select
                                                className="custom-select"
                                                onChange={handleChange}
                                            >
                                                <option value="All">{t("All")}</option>
                                                {props.fetchInternalTransactionDropdownListRes && props.fetchInternalTransactionDropdownListRes.data?.map((item) => (
                                                    <option key={item.party_id} value={item.party_id}>
                                                        {item.partner_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={2} sm={12} className="d-flex justify-content-end align-items-center">
                                    <div className="mt-1 p-3">
                                        {allData && allData ? (
                                            <CSVLink
                                                data={processedData}
                                                headers={headers}
                                                filename={`${org}report.csv`}
                                                target="_blank"
                                                style={{
                                                    textDecoration: 'none',
                                                    color: '#007bff',
                                                }}
                                            >
                                                <i className="fas fa-2x fa-file-csv" style={{ color: '#28a745' }} title="Download CSV"></i>
                                            </CSVLink>
                                        ) : (
                                            <i className="fas fa-2x fa-file-csv" style={{ color: '#dc3545' }} title="CSV Unavailable"></i>
                                        )}
                                    </div>
                                    <div className="p-3">
                                        <InternalTransactionReportPdf
                                            {...props}
                                            processedData={processedData}
                                            org={org}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        {props.loading ? ( // Show the spinner when loading
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: '400px', // Adjust height as needed
                                }}
                            >
                                <Spinner animation="border" role="status">
                                    {/* <span className="visually-hidden"></span> */}
                                </Spinner>
                            </div>
                        ) : data && data.length > 0 ? (
                            <CustomOcpiPaginationTable
                                data={data}
                                allData={processedData}
                                pageCount={pageCount}
                                columns={columns}
                                search={search}
                                setSearch={setSearch}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                filteredData={filteredData}
                                setFilteredData={setFilteredData}
                                initialVisibleColumns={initialVisibleColumns}
                                t={t}
                            />
                        ) : (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <h3 className="loader">{t("No data found")}</h3>
                            </div>
                        )}
                    </Card>
                </Col>
            </Row>
        </Container >
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.ocpi.isLoading,
        list: state.ocpi.fetchInternalTransactionList,
        allList: state.ocpi.fetchAllInternalTransactionList,
        fetchInternalTransactionDropdownListRes: state.ocpi.fetchInternalTransactionDropdownList,
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        getTransactionList: (party_id, currentPage, pageSize) => dispatch(fetchInternalTransactionListAction(party_id, currentPage, pageSize)),
        getAllTransactionList: (party_id, currentPage, pageSize) => dispatch(fetchAllInternalTransactionListAction(party_id, currentPage, pageSize)),
        getInternalEmspDropdownApi: () => dispatch(fetchInternalTransactionDropdownAction()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InternalTransactionReport);
