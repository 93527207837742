import React, { useMemo } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import Table from '../../reacttable/table'

function FleetLiveCharging(props) {
    const data = [
        {
            evse_id: "FLX_HDCHIN23",
            vin: "ABC00001",
            mobile: "1234567890",
        },
        {
            evse_id: "FLX_HDCHIN24",
            vin: "ABC00002",
            mobile: "1234567891",
        },
        {
            evse_id: "FLX_HDCHIN25",
            vin: "ABC00003",
            mobile: "1234567892",
        },
        {
            evse_id: "FLX_HDCHIN26",
            vin: "ABC00004",
            mobile: "1234567893",
        },
        {
            evse_id: "FLX_HDCHIN27",
            vin: "ABC00005",
            mobile: "1234567894",
        },
        {
            evse_id: "FLX_HDCHIN28",
            vin: "ABC00006",
            mobile: "1234567895",
        },
        {
            evse_id: "FLX_HDCHIN29",
            vin: "ABC00007",
            mobile: "1234567896",
        },
        {
            evse_id: "FLX_HDCHIN30",
            vin: "ABC00008",
            mobile: "1234567897",
        },
        {
            evse_id: "FLX_HDCHIN31",
            vin: "ABC00009",
            mobile: "1234567898",
        },
        {
            evse_id: "FLX_HDCHIN32",
            vin: "ABC00010",
            mobile: "1234567899",
        },
    ];

    const columns = useMemo(
        () => [
            {
                Header: "EVSE ID",
                accessor: "evse_id",
            },
            {
                Header: "Mobile",
                accessor: "mobile",
            },
            {
                Header: "VIN",
                accessor: "vin",
            },
        ],
        []
    );
    return (
        <Row className="mt-4">
            <Col>
                <Card className="customercard">
                    <div>
                        {props.loading ? <h3 style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "250px",
                        }}>Loading data...</h3> :
                            data && data.length > 0 ? (
                                <div>
                                    {" "}
                                    <Table data={data} columns={columns}></Table>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <h3 className="loader">No data found</h3>
                                </div>
                            )}
                    </div>
                </Card>
            </Col>
        </Row>
    )
}

export default FleetLiveCharging