const initState = {
    isLoading: false,
    fetchAll: '',
    fetchSingleWallet: '',
    updatecust: '',
    saveSub: '',
    fetchAllSub: '',
    fetchSingleSub: '',
    updateSub: '',
    activateSub: '',
    deactivateSub: '',
    fetchUserSubscription: '',
    fetchWalletCardCount: '',

}
const walletReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOAD_WALLET':
            return { ...state, isLoading: true }
        case 'FETCH_WALLET_DATA':
            return { ...state, fetchAll: action.payload, isLoading: false };
        case 'FETCH_SINGLE_WALLET':
            return { ...state, fetchSingleWallet: action.payload, isLoading: false };
        case 'UPDATE_CUST_W_DATA':
            return { ...state, updatecust: action.payload, isLoading: false }
        case 'SAVE_SUBSCRIPTION_API':
            return { ...state, saveSub: action.payload, isLoading: false }
        case 'FETCH_ALL_SUBSCRIPTION_API':
            return { ...state, fetchAllSub: action.payload, isLoading: false }
        case 'FETCH_SINGLE_SUBSCRIPTION_API':
            return { ...state, fetchSingleSub: action.payload, isLoading: false }
        case 'UPDATE_SUBSCRIPTION_API':
            return { ...state, updateSub: action.payload, isLoading: false }
        case 'ACTIVATE_SUBSCRIPTION_API':
            return { ...state, activateSub: action.payload, isLoading: false }
        case 'DEACTIVATE_SUBSCRIPTION_API':
            return { ...state, deactivateSub: action.payload, isLoading: false }
        case 'FETCH_USER_SUBSCRIPTION_API':
            return { ...state, fetchUserSubscription: action.payload, isLoading: false }
        case 'FETCH_CUSTOMER_WALLET_CARD_COUNT_REDUCER':
            return { ...state, fetchWalletCardCount: action.payload, isLoading: false }
        default:
            return state;
    }
}
export default walletReducer;