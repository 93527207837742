import { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchAllStateGroup } from '../../../../store/actions/accessAction';
import { Container } from 'react-bootstrap';
import { Card, Row, Col } from "react-bootstrap";
import { FaEdit } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import PureBreadcrumbs from "../../breadcrums";




const ViewAllStateGroup = (props) => {
    const { t } = useTranslation();
    useEffect(() => {
        props.getState();
    }, [])

    const data = props.allStateRes && props.allStateRes.data
    // console.log(data);

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <div className="container-fluid pt-4">
                {data ?
                    <Card className=''>
                        <Row>
                            <Col className='mt-2 ml-3' lg={12}>
                                <span >
                                    <h4>
                                        States Grouping View
                                    </h4>
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className='d-flex justify-content-end'>
                                <span className='mr-4 mt-2'>
                                    <a
                                        href={`/dashboard/view_state_group/updateStateGroup/`}
                                        rel="tooltip"
                                        title="Edit"
                                        style={{ fontSize: "xx-large" }}
                                    >
                                        <FaEdit />
                                    </a>
                                </span>
                            </Col>
                        </Row>
                        <Card.Body>
                            <div className="row">
                                <div className="col-md-6">
                                    <Card>
                                        <Card.Body>

                                            <Card.Title> <span><b>Region : </b></span><p className='d-inline-block' style={{ fontWeight: "lighter" }}>North</p></Card.Title>
                                            <span ><b>List of states -</b></span>
                                            <ul className='ml-5'>
                                                {data[0]?.north?.map((item, index) => (
                                                    <li key={index}>{item}</li>
                                                ))}
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-md-6">
                                    <Card>
                                        <Card.Body>
                                            <Card.Title><span><b>Region : </b></span><p className='d-inline-block' style={{ fontWeight: "lighter" }}>South</p></Card.Title>
                                            <span ><b>List of states -</b></span>
                                            <ul className='ml-5'>
                                                {data[0]?.south?.map((item, index) => (
                                                    <li key={index}>{item}</li>
                                                ))}
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-6">
                                    <Card>
                                        <Card.Body>
                                            <Card.Title><span><b>Region : </b></span><p className='d-inline-block' style={{ fontWeight: "lighter" }}>East</p></Card.Title>
                                            <span ><b>List of states -</b></span>
                                            <ul className='ml-5'>
                                                {data[0]?.east?.map((item, index) => (
                                                    <li key={index}>{item}</li>
                                                ))}
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-md-6">
                                    <Card>
                                        <Card.Body>
                                            <Card.Title><span><b>Region : </b></span><p className='d-inline-block' style={{ fontWeight: "lighter" }}>West</p></Card.Title>
                                            <span ><b>List of states -</b></span>
                                            <ul className='ml-5'>
                                                {data[0]?.west?.map((item, index) => (
                                                    <li className='' key={index}>{item}</li>
                                                ))}
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    :
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <h3 className="loader">{t("No data found")}</h3>
                    </div>
                }
            </div>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.access.isLoading,
        allStateRes: state.access.fetchAllStateGroups,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getState: (code) => dispatch(fetchAllStateGroup(code)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewAllStateGroup);