import React from 'react'
import { Card, Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useParams, Redirect } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { allOrgpromotionAction, updatePromotion } from "../../../../store/actions/organisationAction";
import PureBreadcrumbs from "../../breadcrums";
import "../organisation.css"
import Geocode from "react-geocode";
import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next';
import { Bounce, ToastContainer, toast } from 'react-toastify';

Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

const UpdatePromotion = (props) => {
    const { t } = useTranslation();
    const { id } = useParams()
    // console.log("id for update", id)
    const [redirect, setRedirect] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);

    const orgdata = props.allpromotion && props.allpromotion.promotion.filter((item) => item.id === id)

    useEffect(() => {
        // props.getSingleOrgData(id);
        props.all();
    }, []);

    const promotionSchema = Yup.object().shape({
        promotion_code: Yup.string().required('Promotion Code is required'),
        discount: Yup.number().required('Discount is required').typeError('Discount can only be a number').test('len', 'Discount should be in between 0 to 100', val => val.toString().length > 0 && val.toString().length <= 2),
        promotion_name: Yup.string().required('Promotion Name is required'),
    });
    const { register, getValues, setValue, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(promotionSchema), mode: "all" });


    // console.log(orgdata[0])
    const previousStartDate = orgdata && orgdata[0]?.startdate;
    const previousEndDate = orgdata && orgdata[0]?.enddate;
    // console.log("previousEndDate",previousEndDate)
    const PrevStartDate = previousStartDate ? new Date(previousStartDate) : null;
    const PrevEndDate = previousEndDate ? new Date(previousEndDate) : null;
    // console.log("PrevStartDate", PrevStartDate)
    // console.log("PrevEndDate", PrevEndDate)
    const [startdate, setstartdate] = useState(null);
    const [enddate, setenddate] = useState(null);
    // console.log("startdate",startdate)
    // console.log("enddate",enddate)


    useEffect(() => {
        // console.log(orgdata)
        if (orgdata[0]) {
            // console.log("ada")
            setValue('promotion_name', orgdata[0]?.promotion_name, { shouldValidate: true });
            setValue('discount', orgdata[0]?.discount, { shouldValidate: true });
            setValue('promotion_code', orgdata[0]?.promotion_code, { shouldValidate: true });

        }
    }, [props.allpromotion]);

    const onSubmit = async data => {
        if (startdate === null && enddate === null) {
            // console.log("data saved with both previous date successfully",data,PrevStartDate, PrevEndDate);
            props.updatePro(data, PrevStartDate, PrevEndDate)
        } else if (startdate !== null && enddate === null) {
            // console.log("data saved with  startdate, PrevEndDate successfully",data,startdate, PrevEndDate);
            props.updatePro(data, startdate, PrevEndDate)
        } else if (startdate === null && enddate !== null) {
            // console.log("data saved with  PrevStartDate, enddate successfully",data,PrevStartDate, enddate);
            props.updatePro(data, PrevStartDate, enddate)
        }
        else if (PrevStartDate !== null && PrevEndDate !== null) {
            // console.log("data saved with both selected date successfully",data,startdate, enddate);     
            props.updatePro(data, startdate, enddate)
        }
    }

    useEffect(() => {
        if (props.updatePromotionApi.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Promotion has been updated successfully!', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    setRedirect(true)
                },
            });
        }
    }, [props.updatePromotionApi]);

    if (redirect) {
        return <Redirect to='/dashboard/view_promotion' />;
    }

    return (
        <Container fluid className="pb-4">
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={2}>
                </Col>
                <Col xs={12} md={8}>
                    <Card className="tariffcard">
                        <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}><b>{t("Update Promotion")}</b></Card.Text>
                        <Card.Body>
                            <form autoComplete='off' onSubmit={e => e.preventDefault()}>

                                <Row>
                                    <Col lg={6}>
                                        <div className="form-floating">
                                            <label>{t("Enter the promotion code")}  <span className='mandatory-field'>*</span></label>
                                            <input disabled type="text" className={`form-control gray-border ${errors.promotion_code ? 'is-invalid' : ''}`} name="promotion_code" id="floatingInputGridpromotion_code" placeholder={t("Enter the promotion code")} {...register("promotion_code")} />
                                            <div className="invalid-feedback">{errors.promotion_code?.message}</div>
                                        </div>
                                    </Col>

                                    <Col lg={6}>
                                        <div className="form-floating">
                                            <label>{t("Enter specific promotion name")} <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.promotion_name ? 'is-invalid' : ''}`} name="promotion_name" id="floatingInputGridnumber" placeholder={t("Enter specific promotion name")} {...register("promotion_name")} />
                                            <div className="invalid-feedback">{errors.promotion_name?.message}</div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col className="text-center">
                                        {t("Select Promotion Start Date")} :
                                    </Col>
                                    <Col>
                                        <DatePicker
                                            className='gray-border'
                                            selected={startdate || PrevStartDate}
                                            name="start"
                                            onChange={(date) => setstartdate(date)}
                                            selectsStart
                                            startdate={startdate || PrevStartDate}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </Col>
                                </Row><br />
                                <Row>
                                    <Col className="text-center">
                                        {t("Select Promotion End Date")} :
                                    </Col>
                                    <Col>
                                        <DatePicker
                                            className='gray-border'
                                            selected={enddate || PrevEndDate}
                                            onChange={(date) => setenddate(date)}
                                            selectsEnd
                                            startdate={startdate || PrevStartDate}
                                            enddate={enddate || PrevEndDate}
                                            minDate={startdate || PrevStartDate}
                                            showDisabledMonthNavigation
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </Col>
                                </Row><br />

                                <Row>
                                    <Col >
                                        <div className="form-floating">
                                            <label>{t("Enter maximum discount to be applied")} <span className='mandatory-field'>*</span></label>
                                            <input type="number" max="100" className={`form-control gray-border ${errors.discount ? 'is-invalid' : ''}`} name="discount" id="floatingInputGriddiscount" placeholder={t("Enter maximum discount to be applied")} {...register("discount")} />
                                            <div className="invalid-feedback">{errors.discount?.message}</div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mt-3 d-flex justify-content-end">
                                    <Col lg={6} className='d-flex justify-content-end'>
                                        {props.loading ?
                                            <button className="btn lgn-btn" type="button" disabled>
                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                &nbsp;
                                                {t("Saving...")}
                                            </button>
                                            :
                                            <button className="lgn-btn btn" type="submit" onClick={handleSubmit(onSubmit)}>{t("UPDATE PROMOTION")}</button>
                                        }
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                        <br /><br />
                    </Card>

                </Col>
                <Col xs={12} md={2}>
                </Col>

            </Row>
        </Container>
    )
}



const mapStateToProps = (state) => {
    return {
        loading: state.operator.isLoading,
        allpromotion: state.operator.allpromotion,
        updatePromotionApi: state.operator.updatePromotion,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        all: () => dispatch(allOrgpromotionAction()),
        updatePro: (data, startdate, enddate) => dispatch(updatePromotion(data, startdate, enddate))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdatePromotion)
