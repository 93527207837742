
import React, { useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import "../../reacttable/reactTable.css";
import Table from "../../reacttable/table";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { connect } from "react-redux";
import { allCustomerBookingList } from "../../../../store/actions/evseAction";
import CustomReportPaginationTable from "../../report/adminReport/customReportPaginationTable";

function CustomerBookingList(props) {
    // console.log(props)
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const document_count = props.activeBooking && props.activeBooking.document_count;
    const defaultPageSize = 10;
    const pageCount = Math.ceil(document_count / defaultPageSize)
    const { allData } = props;

    useEffect(() => {
        props.activeBookingList(currentPage, pageSize);
    }, [currentPage, pageSize]);

    const data = props.activeBooking && props.activeBooking.data;

    const handleDelete = (reserveid) => {
        // console.log(reserveid)
        const isConfirmed = window.confirm("Are you sure you want to Cancle the booking?");

        if (isConfirmed) {
            props.BookingDelete(reserveid);
        } else {
        }
    }

    if (props.delete.statuscode === 200) {
        window.location.reload();
    }

    const columns = useMemo(
        () => [
            { Header: "User Name", accessor: "loginid" },
            { Header: "Station Name", accessor: "name" },
            { Header: "EVSE ID", accessor: "evse_id" },
            { Header: "Address", accessor: "address" },
            { Header: "City", accessor: "city" },
            { Header: "Booking ID", accessor: "bookingid" },
            { Header: "Reservation ID", accessor: "reserveid" },
            { Header: "Date", id: "date1", accessor: "date" },
            { Header: "Start", accessor: "begin" },
            { Header: "End", accessor: "end" },
            // {
            //     Header: 'Booking Status',
            //     id: 'date2',
            //     accessor: 'date',
            //     Cell: ({ value }) => {
            //         const [valueDay, valueMonth, valueYear] = value.split('/');
            //         const [todaysDay, todaysMonth, todaysYear] = todaysDate.split('/');

            //         if (
            //             (+valueYear < +todaysYear) ||
            //             (+valueYear === +todaysYear && +valueMonth < +todaysMonth) ||
            //             (+valueYear === +todaysYear && +valueMonth === +todaysMonth && +valueDay < +todaysDay)
            //         ) {
            //             return <span style={{ color: 'red' }}>Booking Expired</span>;
            //         } else {
            //             return <span style={{ color: 'green' }}>Booking Scheduled</span>;
            //         }
            //     },
            // },
            // {
            //     Header: 'Cancel Reservation',
            //     id: "reserveid1",
            //     accessor: 'reserveid',
            //     Cell: ({ row }) => {
            //         const { original } = row;
            //         const status = original.status;
            //         // console.log("status",status)
            //         if (status === "RESERVE") {
            //             return (
            //                 <div>
            //                     <span>
            //                         <i style={{ color: 'red', cursor: 'pointer' }} onClick={() => handleDelete(row.original.reserveid)}>
            //                             &#10060;
            //                         </i>
            //                     </span>
            //                 </div>
            //             );
            //         } else {
            //             return null;
            //         }
            //     },

            // },
            {
                Header: "Status", accessor: "status",
                Cell: (stats) => {
                    return t(stats.value)
                }
            },

        ],
        []
    );

    const initialVisibleColumns =
        [
            'loginid', 'name', 'evse_id', 'address', 'city',
            'bookingid', 'reserveid', 'date', 'status', 'begin', 'end'
        ];

    return (

        <Card className="customercard">
            <div className="table-header">{t("Customer Booking Data")}</div>
            {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "350px",
            }}>Loading data...</h3> :
                data && data.length > 0 ? (
                    <div>
                        <CustomReportPaginationTable
                            data={data}
                            allData={allData}
                            pageCount={pageCount}
                            columns={columns}
                            search={search}
                            setSearch={setSearch}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            filteredData={filteredData}
                            setFilteredData={setFilteredData}
                            loading={props.loading}
                            initialVisibleColumns={initialVisibleColumns}
                            t={t}
                        />
                    </div>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <h3 className="loader">{t("No data found")}</h3>
                    </div>
                )}
        </Card>
    )

}


const mapStateToProps = (state) => {
    return {
        loading: state.evse.isEvseLoading,
        activeBooking: state.evse.fetchalluserbooking,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        activeBookingList: (currentPage, pageSize) => dispatch(allCustomerBookingList(currentPage, pageSize)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerBookingList);