import React, { useEffect, useState, useCallback } from 'react';
import { FaFilter } from 'react-icons/fa';
import debounce from 'lodash.debounce';

function CustomReportPaginationTable({
    data,
    allData,
    pageCount,
    columns,
    search,
    setSearch,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    filteredData,
    setFilteredData,
    loading,
    t,
    initialVisibleColumns,
}) {
    const [showCheckboxes, setShowCheckboxes] = useState(false);
    // Here you are Initializing visibleColumns with initially visible columns
    const [visibleColumns, setVisibleColumns] = useState(
        columns.reduce((acc, col) => {
            acc[col.accessor] = initialVisibleColumns?.includes(col.accessor);
            return acc;
        }, {})
    );

    const toggleCheckboxes = () => {
        setShowCheckboxes((prevState) => !prevState);
    };

    const toggleColumnVisibility = (accessor) => {
        setVisibleColumns((prevState) => ({
            ...prevState,
            [accessor]: !prevState[accessor],
        }));
    };

    const debouncedSearch = useCallback(
        debounce((searchTerm) => {
            if (!searchTerm) {
                setFilteredData(data);
                return;
            }
            const filtered = allData && allData.filter((item) => {
                // Log the entire item object
                // console.log("Item being checked: ", item);

                // Log individual values if needed
                // Object.values(item).forEach(value => {
                //     console.log("Value being checked: ", value);
                // });

                // Filter logic
                return Object.values(item).some((value) =>
                    value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
                );
            });
            setFilteredData(filtered);
        }, 500),
        [data, allData]
    );


    useEffect(() => {
        debouncedSearch(search);
        return () => {
            debouncedSearch.cancel();
        };
    }, [search, debouncedSearch]);


    // debounce function for performance of search input below the table
    const debouncedHandlePageInputChange = useCallback(
        debounce((newPage) => {
            if (!isNaN(newPage) && newPage > 0 && newPage <= pageCount) {
                setCurrentPage(newPage);
            }
        }, 500),
        [pageCount]
    );

    const handlePageInputChange = (e) => {
        const newPage = Number(e.target.value);
        debouncedHandlePageInputChange(newPage);
    };

    const filteredColumns = columns?.filter((col) => visibleColumns[col.accessor]);

    return (
        <div>
            {/* Search filter for global search */}
            <div className="row pb-2">
                <div className="col-md-12">
                    <div className="col-md-12 pt-2">
                        <span className='float-right ml-3 mt-0'>
                            <FaFilter
                                onClick={toggleCheckboxes}
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                            />
                        </span>
                        <div className="row float-right">
                            <div className="col-md-12">
                                <span>
                                    <input
                                        className="SearchFilter p-3"
                                        placeholder={t("Search")}
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <i
                                        style={{ cursor: "pointer", marginLeft: "-30px" }}
                                    >
                                        <img
                                            className="pr-1"
                                            alt="Search icon"
                                            src={`${process.env.PUBLIC_URL}/images/admin/inputSearch.svg`}
                                            style={{ height: "15px" }}
                                        />
                                    </i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Render checkboxes for each column */}
            <div className="column-checkboxes-toggle">
                {showCheckboxes && (
                    <div className="column-checkboxes">
                        {columns.map((col) => (
                            <label key={col.accessor} style={{ fontSize: "15px" }}>
                                <input
                                    style={{ fontSize: "11px" }}
                                    type="checkbox"
                                    checked={visibleColumns[col.accessor]}
                                    onChange={() => toggleColumnVisibility(col.accessor)}
                                />
                                {t(col.Header)}
                            </label>
                        ))}
                    </div>
                )}
            </div>

            {/* Loading or data display */}
            {loading ? (
                <h3 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '500px' }}>
                    {t("Loading data...")}
                </h3>
            ) : (
                <div>
                    {filteredData && filteredData.length > 0 ? (
                        <div>
                            <table className="custom-table">
                                <thead>
                                    <tr>
                                        {filteredColumns?.map((col) => (
                                            <th key={col.accessor}>{t(col.Header)}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData?.map((item, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {filteredColumns?.map((col) => (
                                                <td key={col.accessor}>
                                                    {col.Cell
                                                        ? col.Cell({ cell: item[col.accessor], item })
                                                        : item[col.accessor]}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* Pagination controls */}
                            {search === '' &&
                                <div className="d-flex justify-content-center pt-2">
                                    <button
                                        className="columnsBtn mr-1"
                                        onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
                                        disabled={currentPage <= 1}
                                        style={{ margin: "5px" }}
                                    >
                                        Previous
                                    </button>
                                    <span className='m-1'>
                                        Page
                                        <input
                                            type="number"
                                            className='gray-border ml-1'
                                            placeholder={currentPage}
                                            onChange={handlePageInputChange}
                                            style={{ width: '80px', margin: '0.5px' }}
                                        />
                                        {pageCount !== null && pageCount !== undefined ? ` of ${pageCount}` : ''}
                                    </span>
                                    <button
                                        className="columnsBtn mr-1 p-1"
                                        onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                                        disabled={currentPage >= pageCount}
                                        style={{ margin: "5px" }}
                                    >
                                        Next
                                    </button>
                                </div>}
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <h3 className="loader">{t("No data found")}</h3>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default CustomReportPaginationTable;
